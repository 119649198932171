/*==========================
	Custom css
==========================*/
.text-gray-dark {
  color: #9a9a9a;
}
.header-nav .nav > li.active > a {
  color: #fff;
}
.header-nav .nav > li.active > a,
.header-nav .nav > li:hover > a {
  color: black;
}
/* Home Pages */
.navbar-nav > li > a > span {
  font-size: 18px;
}
.site-header.header-transparent .top-bar {
  border: 0;
}
.dlab-topbar-left ul li,
.dlab-topbar-right ul li {
  font-size: 14px;
  color: #fff;
  opacity: 0.7;
  vertical-align: middle;
}
.dlab-topbar-left ul li i,
.dlab-topbar-right ul li i {
  font-size: 18px;
  vertical-align: middle;
}
.text-black .dlab-topbar-left ul li,
.text-black .dlab-topbar-right ul li {
  color: #000;
}
.logo-header,
.is-fixed .logo-header {
  height: 60px;
}
.dlab-topbar-right ul li {
  padding-left: 15px;
}
.header-nav .nav > li > a,
.is-fixed .header-nav .nav > li > a {
  text-transform: capitalize;
  font-weight: 600;
  padding: 20px 12px;
}
.extra-nav {
  padding-top: 10px;
  padding-bottom: 10px;
}
.is-fixed .extra-nav {
  padding-top: 10px;
  padding-bottom: 10px;
}
/* Slider */
.overlay-black-dark:after {
  opacity: 0.85;
}
.main-slider {
  padding-bottom: 0;
}
.main-slider .dlab-bnr-inr-entry {
  vertical-align: middle;
}

/* Owl Slider */
.slide-item {
  position: relative;
}
.slide-item .slide-content {
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  padding-bottom: 180px;
  padding-top: 100px;
}
.slide-item-img img {
  object-fit: cover;
  height: 850px;
}

.slide-content-box {
  position: relative;
  top: 30%;
  transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transition: all 1s ease 0.5s;
  -webkit-transition: all 1s ease 0.5s;
  -o-transition: all 1s ease 0.5s;
  -moz-transition: all 1s ease 0.5s;
}
.main-slider .tp-shapewrapper.ov-tp {
  background-color: rgba(0, 0, 0, 0.75);
}
@media only screen and (max-width: 1200px) {
  .slide-item-img img {
    height: 750px;
  }
}
@media only screen and (max-width: 991px) {
  .dlab-topbar-left ul li,
  .dlab-topbar-right ul li {
    font-size: 13px;
  }
}
@media only screen and (max-width: 767px) {
  .slide-item-img img {
    height: 650px;
  }
  .product-gallery-bx.on-show-slider {
    position: sticky;
    top: 80px;
  }
}
/* service box In slide */
.service-box-slide {
  bottom: 0;
  padding: 30px 0;
  position: absolute;
  width: 100%;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  z-index: 1;
}
.service-box-slide .container {
  display: block;
}
.service-box-slide .bx-style-1 {
  border-color: rgba(255, 255, 255, 0.5);
}
.service-box-slide .bx-style-1:hover {
  background-color: rgba(255, 255, 255, 0.05);
}
.button-lg {
  font-size: 16px;
  padding: 15px 25px;
}
.button-lg.outline-2 {
  padding: 13px 23px;
}
.service-box-slide p {
  opacity: 0.9;
  font-size: 15px;
}
.service-box-slide .icon-content {
  overflow: unset;
}
.dlab-bnr-inr-entry.full-height {
  min-height: 500px;
  max-height: 500px;
}
/* abuot box */
.abuot-box {
  background: #ffffff none repeat scroll 0 0;
  border-radius: 4px;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.05);
  padding: 40px 20px 20px;
  position: relative;
  width: 120%;
  z-index: 1;
  margin-top: 40px;
}
.about-img img {
  border-radius: 8px;
}
.text-gray-dark {
  color: #5a5a5a;
}

/* box item service */
.item-service-content {
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  padding: 30px;
  text-align: center;
}
.item-service-content p {
  font-size: 15px;
}
.box-item-service .icon-bx-md {
  color: #b2b2b2;
}
.box-item-service {
  position: relative;
}
.box-item-service .site-button.m-b40 {
  margin-bottom: 40px;
}
.development-box {
  position: relative;
  z-index: 1;
}
.development-box:after {
  border-top: 2px dashed #e2e2e2;
  content: '';
  display: block;
  height: 2px;
  margin-top: -1px;
  position: absolute;
  top: 50%;
  z-index: -1;
  width: 100%;
}
.round-center {
  background: #ffffff none repeat scroll 0 0;
  border: 4px solid;
  border-radius: 25px;
  display: inline-block;
  height: 25px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 25px;
  z-index: 1;
}

.box-item-service:hover .item-service-content,
.box-item-service:hover h5,
.box-item-service:hover .icon-bx-md {
  color: #fff;
}
/* Why Choose us */
.choseus-tabs .nav {
  text-align: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.choseus-tabs .nav li {
  display: inline-block;
}
.choseus-tabs .nav li a:hover {
  border: 0;
}
.choseus-tabs .nav li a {
  color: #acacac;
  font-size: 16px;
  border-bottom: 2px solid rgba(0, 0, 0, 0);
  margin-bottom: -1px;
  padding: 0 10px 15px 15px;
  display: inline-block;
}
.choseus-tabs .nav li a.active {
  color: #fff;
}
.chosesus-content .list-checked li:before {
  content: '\e64d';
  font-family: 'themify';
  font-size: 18px;
}
.chosesus-content .list-checked li {
  padding: 8px 5px 8px 30px;
  margin-bottom: 10px;
}
.chosesus-content .list-checked li span {
  opacity: 0.7;
}
.chosesus-content .abuot-box {
  margin-top: 50px;
  padding-left: 30px;
  padding-right: 30px;
  left: -20%;
}
.chosesus-content .abuot-box p {
  color: #363636;
  font-size: 15px;
}

/* choses-info */
.choses-info {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  position: relative;
  z-index: 1;
  text-align: center;
}
.choses-info-content [class*='col-'] {
  border-right: 1px solid rgba(255, 255, 255, 0.1);
}
.choses-info-content [class*='col-']:last-child {
  border-right: 0;
}

/* portfolio box */
.portfolio-box .icon-bx-xs {
  border: 1px solid rgba(255, 255, 255, 0.2);
  background-color: rgba(0, 0, 0, 0);
  color: #fff;
  border-radius: 0;
  font-size: 18px;
}
.portfolio-box .dlab-info-has {
  z-index: 11;
}
.dlab-img-overlay1:hover:before,
.dlab-box:hover .dlab-img-overlay1:before {
  opacity: 0.8;
}
.portfolio-carousel .item {
  padding: 0 15px;
}
.portfolio-carousel .item .dlab-box,
.portfolio-section .dlab-box {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2);
}
.portfolio-section .container-fluid {
  padding-left: 100px;
  padding-right: 100px;
}
/* testimonial video */
.testimonial-text.video {
  padding: 15px;
}
.video-testimonial {
  overflow: hidden;
  border-radius: 4px;
  position: relative;
}
.video-testimonial-play {
  background: rgba(0, 0, 0, 0.5) none repeat scroll 0 0;
  height: 100%;
  left: 0;
  position: absolute;
  text-align: center;
  top: 0;
  width: 100%;
}
.video-testimonial-play a {
  color: #ffffff;
  font-size: 45px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
}
.op5 {
  opacity: 0.5;
}
.op6 {
  opacity: 0.6;
}
.op7 {
  opacity: 0.7;
}
.op8 {
  opacity: 0.8;
}
.op9 {
  opacity: 0.9;
}
/* inquiry-form */
.inquiry-form {
  background-color: #fff;
  padding: 40px;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.3);
  border-radius: 15px;
  margin-top: -40px;
}
.inquiry-form .input-group-addon {
  border-width: 0 0 1px;
  font-size: 18px;
  padding: 9px 0 0;
  vertical-align: top;
  border-style: solid;
}
.inquiry-form .form-control {
  border-width: 0 0 1px;
  font-size: 16px;
}
textarea.form-control {
  height: auto;
}
.footer-top {
  background-color: #292929;
}
.footer-bottom {
  background-color: #292929;
}
.footer-contact-info.service-box-slide {
  padding: 0;
  position: unset;
  border-top: 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.service-box-slide .icon-sm img,
.footer-contact-info .icon-sm img {
  border-radius: 2px;
  height: 20px;
  width: auto;
}
.footer-contact-info.service-box-slide p {
  font-size: 12px;
  opacity: 0.5;
}
.footer-contact-info .icon-bx-wraper .dlab-tilte {
  font-size: 16px;
}
.footer-contact-info .icon-sm {
  text-align: left;
  width: 25px;
}
.footer-contact-info .icon-sm i {
  font-size: 20px;
}
.exp-services .section-head p {
  font-size: 18px;
  line-height: 32px;
  max-width: 1000px;
}
.exp-services .section-head p strong {
  font-size: 22px;
  font-weight: 600;
}
/* footer */
footer .widget_services .list-2 li {
  width: 50%;
  float: left;
  list-style-type: circle !important;
  list-style-position: inside;
  padding: 5px 5px 5px 0px;
}
footer .widget_services .list-2 li:before {
  content: none;
}
.subscribe-form input {
  background-color: rgba(255, 255, 255, 0.05);
  border: 0;
  border-radius: 0 !important;
  height: 50px;
  padding: 10px 20px;
  margin-right: 0;
  color: #fff;
  font-size: 13px;
}
.subscribe-form .input-group-btn {
  padding-left: 0;
}
.footer-content-box {
  border: 1px solid rgba(255, 255, 255, 0.04);
  padding: 25px 30px;
  border-radius: 6px;
  background-color: rgba(255, 255, 255, 0.03);
}

/* Inner Pages */
.abuot-box.left {
  right: auto;
  left: -20%;
  margin-top: 50px;
}
.about-service .icon-bx-wraper {
  border: 1px solid rgba(255, 255, 255, 0.1);
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
}
/* pulsating circle buy now button on preview */

.dlab-bnr-inr-entry {
  vertical-align: bottom;
}
.inquiry-form.inner {
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  box-shadow: none;
  margin-top: 0;
  padding: 20px;
  position: sticky;
  top: 100px;
}
.icon-bx-wraper.expertise {
  background-color: rgba(0, 0, 0, 0.01);
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 13px;
}
/* Site Button */
/* Theme BTN */
.site-button:hover:after,
.site-button-secondry:hover:after {
  left: 5%;
  width: 90%;
  transform: skewX(25deg);
  -o-transform: skewX(25deg);
  -ms-transform: skewX(25deg);
  -webkit-transform: skewX(25deg);
  -ms-transform: skewX(25deg);
}

.site-button-secondry:after,
.site-button:after {
  background-color: #000;
  content: '';
  height: 100%;
  left: 50%;
  opacity: 0.15;
  position: absolute;
  top: 0;
  transform: skewX(0);
  -o-transform: skewX(0);
  -ms-transform: skewX(0);
  -webkit-transform: skewX(0);
  -moz-transform: skewX(0);
  transition: all 0.5s ease 0s;
  -moz-transition: all 0.5s ease 0s;
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  -ms-transition: all 0.5s ease 0s;
  width: 0;
  z-index: -1;
}
.site-button-secondry:after {
  background-color: #fff;
}
.site-button,
.site-button-secondry {
  position: relative;
  z-index: 1;
  overflow: hidden;
  vertical-align: middle;
}
/* contact-page */
.contact-page .container {
  display: ;
}
.contact-page {
  padding-bottom: 50px;
  padding-top: 200px;
}
.contact-page .inquiry-form {
  margin-top: 0;
}
.contact-page .bx-style-1 {
  border-color: rgba(255, 255, 255, 0.1);
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  color: #fff;
}
.contact-page .bx-style-1 p {
  opacity: 0.8;
}
.contact-page-1 .inquiry-form {
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  box-shadow: none;
  margin-top: 0;
  padding: 40px;
}
.contact-page-1 .icon-bx-wraper.bx-style-1,
.contact-page-1 .icon-bx-wraper.bx-style-2 {
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 15px;
}
/* Box Layout */

/* Responsive */
@media only screen and (max-width: 1200px) {
  .portfolio-section .container-fluid {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (max-width: 1024px) {
  .abuot-box {
    margin-top: 20px;
  }
}
@media only screen and (max-width: 991px) {
  .dlab-topbar-left ul li {
    padding-right: 5px;
  }
  .dlab-topbar-right ul li {
    padding-left: 5px;
  }
  .navbar-toggler,
  .is-fixed .navbar-toggler {
    margin: 22px 0 20px 15px;
    float: right;
  }
  .chosesus-content .p-l30 {
    padding-left: 0;
  }
  .choses-info-content h2 {
    font-size: 28px;
    margin-bottom: 0;
    line-height: 35px;
  }
  .choses-info-content h4 {
    font-size: 14px;
    margin-bottom: 0;
  }
  .header-nav .nav > li > a,
  .is-fixed .header-nav .nav > li > a {
    padding: 10px 15px;
  }
  .header-nav .nav > li.active > a,
  .header-nav .nav > li:hover > a {
    color: #000;
  }
}
@media only screen and (max-width: 767px) {
  .slide-content-box {
    text-align: center;
  }
  .owl-slider.owl-theme .owl-dots {
    bottom: 180px;
    margin-top: 0;
    position: absolute;
    right: auto;
    text-align: center;
    top: auto;
    transform: translateY(-50%);
    width: 100%;
    margin-bottom: 10px;
  }
  .site-header .navbar-toggler,
  .site-header .is-fixed .navbar-toggler {
    margin: 22px 0 22px 10px;
  }
  .slide-content .button-lg {
    font-size: 13px;
    padding: 10px 15px;
  }
  .text-white .site-button.outline {
    padding: 8px 15px;
  }
  .abuot-box {
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    border-radius: 0;
    box-shadow: none;
    margin-top: 0;
    padding: 15px 15px 0;
    position: relative;
    width: 100%;
    z-index: unset;
  }
  .abuot-box [class*='col-'] {
    padding: 0;
  }
  .choseus-tabs .title-head {
    display: unset;
  }
  .chosesus-content .abuot-box {
    background-color: rgba(255, 255, 255, 0.05);
    border-radius: 5px;
    left: auto;
    margin-top: 0;
    padding: 20px;
  }
  .chosesus-content .abuot-box p {
    color: #ffffff;
    font-size: 14px;
    opacity: 0.8;
  }
  .choses-info-content [class*='col-'] {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  .inquiry-form {
    margin-top: 0;
  }
  .contact-page {
    padding-bottom: 50px;
    padding-top: 100px;
  }
}

/* Write text */
.ml2 {
  color: #ffffff;
  font-size: 30px;
  font-weight: 400;
  margin: 0;
}
.ml2 .letter {
  display: inline-block;
  line-height: 1em;
}
.ml12 {
  font-weight: 300;
  font-size: 1.8em;
  text-transform: uppercase;
  letter-spacing: 0.5em;
  color: #000;
}

.ml12 .letter {
  display: inline-block;
  line-height: 1em;
}
#loading-area {
  background-image: none;
  display: table;
  text-align: center;
  background-color: rgba(0, 0, 0, 0);
  background-size: cover;
}
#loading-area .ml12 {
  display: table-cell;
  vertical-align: middle;
  color: #000;
  width: 100%;
}

/* Time Line Css ========================== */
/* Time Line */
.time-line {
  position: relative;
}
.time-line:after {
  border-radius: 2px;
  content: '';
  height: 100%;
  position: absolute;
  right: 50%;
  top: 0;
  width: 4px;
  display: block;
}
.time-line .line-left-box,
.time-line .line-right-box {
  width: 40%;
  position: relative;
  z-index: 1;
}
.line-right-box .line-content-box,
.line-left-box .line-content-box {
  background: #ffffff none repeat scroll 0 0;
  border-radius: 12px;
  box-shadow: 1px 1px 62px 0 rgba(0, 0, 0, 0.1);
  padding: 30px;
}
.line-left,
.line-right {
  position: relative;
  display: table;
  width: 100%;
}

.line-left:after {
  content: '';
  height: 4px;
  position: absolute;
  top: 50%;
  left: 40%;
  width: 10%;
}
.line-right:after {
  content: '';
  height: 4px;
  position: absolute;
  right: 40%;
  top: 50%;
  width: 10%;
}
.time-line .line-left-box {
  float: left;
}
.time-line .line-right-box {
  float: right;
}
.time-line .line-left-box .line-content-box {
  padding-right: 60px;
}
.time-line .line-right-box .line-content-box {
  padding-left: 60px;
}
.line-num {
  border-radius: 40px;
  box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.3);
  color: #ffffff;
  font-size: 40px;
  font-weight: 700;
  font-family: 'Nunito', sans-serif;
  height: 80px;
  line-height: 80px;
  text-align: center;
  width: 80px;
}
.time-line .line-left-box .line-num {
  position: absolute;
  right: -40px;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}
.time-line .line-right-box .line-num {
  position: absolute;
  left: -40px;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}
.faa-horizontal.animated {
  -webkit-animation: horizontal 5s ease infinite;
  animation: horizontal 5s ease infinite;
}
/* Faqs */
.faq-1 .panel {
  margin-bottom: 15px;
  transition: all 0.5s;
}
.faq-1 .acod-title a {
  padding: 20px;
  color: #000;
}
.faq-1 .panel:hover {
  box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.1);
  transition: all 0.5s;
}
.faq-1 .acod-head a::after {
  top: 50%;
  transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  right: 20px;
}
.faq-video {
  position: relative;
}
.faq-video .play-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  font-size: 65px;
}
@keyframes horizontal {
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  6% {
    -webkit-transform: translate(20px, 0);
    transform: translate(0, 20px);
  }
  12% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  18% {
    -webkit-transform: translate(0, 20px);
    transform: translate(0, 20px);
  }
  24% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  30% {
    -webkit-transform: translate(0, 20px);
    transform: translate(0, 20px);
  }
  100%,
  36% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}

@media only screen and (max-width: 767px) {
  .line-left,
  .line-right {
    margin-bottom: 30px;
  }
  .line-num {
    height: 40px;
    width: 40px;
    font-size: 16px;
    line-height: 40px;
  }
  .time-line:after,
  .line-right:after {
    right: auto;
    left: 0;
  }
  .time-line .line-left-box,
  .time-line .line-right-box {
    width: 90%;
    float: right;
  }
  .time-line .line-left-box .line-num,
  .time-line .line-right-box .line-num {
    left: -20px;
  }
  .time-line .line-left-box {
    padding-right: 30px;
  }
  .time-line .line-right-box {
    padding-left: 30px;
  }
  .ft-gallery-box {
    margin-top: 0;
  }
  .time-line .line-left-box .line-content-box {
    padding-right: 30px;
  }
  .time-line .line-right-box .line-content-box {
    padding-left: 30px;
  }
  .line-left:after {
    left: 0;
  }
  .time-line .line-right-box,
  .time-line .line-left-box {
    padding: 0;
  }
  .abuot-box.left {
    left: auto;
  }
  .box-title {
    font-size: 22px;
    line-height: 35px;
  }
}
.owl-slider-banner {
  position: relative;
}
.owl-slider-banner .owl-theme .owl-dots .owl-dot {
  border: 1px solid rgba(255, 255, 255, 0.4);
}
.owl-slider-banner .owl-theme .owl-dots .owl-dot.active,
.owl-slider-banner .owl-theme .owl-dots .owl-dot:hover {
  border: 1px solid #fff;
}
.owl-slider-banner .owl-theme .owl-dots .owl-dot span {
  background-color: rgba(255, 255, 255, 0.4);
}
.owl-slider-banner .owl-theme .owl-dots .owl-dot.active span,
.owl-slider-banner .owl-theme .owl-dots .owl-dot:hover span {
  background-color: rgba(255, 255, 255, 1);
}
/* Effect 10: Corner indicator */
.la-anim-10 {
  position: fixed;
  top: 0;
  right: 0;
  width: 300px;
  height: 300px;
  background: #84ba3f;
  -webkit-transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  -webkit-transform: translateX(100%) translateY(-100%) rotate(45deg);
  transform: translateX(100%) translateY(-100%) rotate(45deg);
  pointer-events: none;
  z-index: 9999999999999999;
}

.la-anim-10.la-animate {
  -webkit-transform: translateX(50%) translateY(-50%) rotate(45deg);
  transform: translateX(50%) translateY(-50%) rotate(45deg);
}

/* Loading circle idea from http://codepen.io/Metty/details/lgJck */
.la-anim-10::before,
.la-anim-10::after {
  position: absolute;
  bottom: 30px;
  left: 50%;
  display: block;
  border: 5px solid #fff;
  border-radius: 50%;
  content: '';
}

.la-anim-10::before {
  margin-left: -40px;
  width: 80px;
  height: 80px;
  border-right-color: rgba(0, 0, 0, 0.2);
  border-left-color: rgba(0, 0, 0, 0.2);
  -webkit-animation: rotation 3s linear infinite;
  animation: rotation 3s linear infinite;
}

.la-anim-10::after {
  bottom: 50px;
  margin-left: -20px;
  width: 40px;
  height: 40px;
  border-top-color: rgba(0, 0, 0, 0.2);
  border-bottom-color: rgba(0, 0, 0, 0.2);
  -webkit-animation: rotation 1s linear infinite;
  animation: rotation 1s linear infinite;
}

@-webkit-keyframes rotation {
  0% {
    -webkit-transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Error */
.dz_error-404 .dz_error {
  border-radius: 50%;
  font-size: 70px;
  font-weight: 700;
  height: 200px;
  line-height: 200px;
  margin: 20px 0;
  text-align: center;
  width: 200px;
}
.dz_error-405 .dz_error {
  color: #afafaf;
  font-family: poppins;
  font-size: 200px;
  font-weight: 700;
  line-height: 200px;
  text-align: center;
}
.dz_error-405 .error-head {
  font-size: 50px;
  font-weight: 700;
}
.dz_error-405 .error-content {
  font-size: 21px;
  font-weight: 500;
}
.dz_error-405 .form-control.bg-black {
  background-color: rgba(0, 0, 0, 0.1);
  font-size: 15px;
}
.dz-error-full {
  height: 100vh;
}
.shop-account .site-button.active.show {
  color: #fff;
}

/* responsive */
@media only screen and (max-width: 1024px) {
  .title-box span {
    display: none;
  }
  .sticky-top .inquiry-form .g-recaptcha {
    transform: scale(0.75);
    -webkit-transform: scale(0.75);
    -moz-transform: scale(0.75);
    -o-transform: scale(0.75);
    transform-origin: 0 0;
    -o-transform-origin: 0 0;
    -moz-transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
    margin-right: -60px;
  }
}
@media only screen and (max-width: 991px) {
  .header-nav .nav > li .sub-menu,
  .header-nav .nav > li .mega-menu {
    border-top: 0;
    box-shadow: none;
  }
  .choseus-tabs .nav {
    display: table;
  }
  .choseus-tabs .nav li {
    display: table-cell;
  }
  .dz_error-405 .subscribe-form {
    margin: auto;
  }
  .dz_error-405 {
    text-align: center;
  }
}
@media only screen and (max-width: 767px) {
  .shop-action .icon-bx-wraper.left {
    text-align: center;
  }
  .shop-action .icon-bx-wraper.left .icon-md {
    float: none;
    margin-right: 0;
    margin-bottom: 15px;
  }
  .top-bar {
    display: none;
  }
  .slide-item .slide-content {
    padding-top: 60px;
  }
  .service-box-slide p {
    font-size: 13px;
  }
  .choseus-tabs .nav li a {
    font-size: 14px;
  }
  .chosesus-content .about-img {
    margin-bottom: 20px;
  }
  .dz_error-404 .dz_error {
    font-size: 35px;
    height: 100px;
    line-height: 100px;
    margin: 0;
    width: 100px;
  }
  .dz_error-404 .error-head {
    font-size: 20px;
    margin-bottom: 5px;
  }
  .dz_error-405 .dz_error {
    font-size: 120px;
    line-height: 120px;
  }
  .dz_error-405 .error-head {
    font-size: 35px;
    margin: 10px 0;
  }
  .dz_error-405 .dlab-divider {
    margin: 15px 0;
  }
  .dlab-coming-soon .logo-header {
    margin: auto;
  }
}

@media only screen and (max-width: 599px) {
  .footer-bottom .text-left,
  .footer-bottom .text-right {
    text-align: center !important;
  }
  .choseus-tabs .nav li a {
    font-size: 13px;
    padding: 5px;
  }
}
@media only screen and (max-width: 360px) {
  .abuot-box {
    margin: 0;
  }
  .abuot-box.left {
    margin-top: 30px;
  }
  .our-about-info .box-title {
    margin: 20px 0;
  }
}
/* New Page */
/* form-tax */
.form-tax {
  box-shadow: 10px 10px 40px 0 rgba(39, 50, 114, 0.25);
  max-width: 700px;
  margin-left: -50px;
}
.form-tax label {
  font-size: 15px;
  text-transform: capitalize;
}
.form-tax .bootstrap-select .dropdown-toggle,
.form-tax input.form-control {
  border: 2px solid #e7ecf1 !important;
  border-radius: 4px;
  height: 45px;
  padding: 10px 20px;
}
.bootstrap-select.btn-group .dropdown-toggle .filter-option {
  font-size: 15px;
}
.form-tax .site-button {
  padding: 12px 25px;
}
.form-tax .form-group {
  margin-bottom: 15px;
}
.tax-info-chart .form-tax {
  box-shadow: none;
  margin-left: 0;
  max-width: 100%;
}
.chart-box {
  padding: 30px;
  background: #fff;
  width: 100%;
}
/* Team */
.team-section {
  position: relative;
  overflow: hidden;
}
.team-section .team-content {
  position: absolute;
  bottom: -46px;
  width: 100%;
  padding: 10px 25px;
  transition: all 0.5s;
}
.team-section:hover .team-content {
  bottom: 0;
  transition: all 0.5s;
}
.team-section .team-content a {
  font-size: 18px;
  margin-right: 10px;
}

.dlab-box-icon .icon-cell > div i {
  line-height: 65px;
  display: block;
  transition: all 0.5s;
}
.dlab-box-icon .icon-cell > div {
  width: 65px;
  height: 65px;
  overflow: hidden;
  display: block;
}
.dlab-box-icon:hover .icon-cell span {
  margin-top: -65px;
  transition: all 0.5s;
}
.dlab-box-icon .icon-cell span {
  display: block;
  transition: all 0.5s;
}
.site-button-link.border-link {
  border-bottom: 2px solid;
  border-radius: 0;
  padding: 0;
}
@media only screen and (max-width: 1024px) {
  .facility .icon-bx-wraper.dlab-box-icon {
    padding: 40px 20px;
  }
}
@media only screen and (max-width: 768px) {
  .facility .icon-bx-wraper.dlab-box-icon {
    padding: 70px;
  }
  .form-tax {
    max-width: 90%;
    margin: auto;
  }
}
@media only screen and (max-width: 360px) {
  .facility .icon-bx-wraper.dlab-box-icon {
    padding: 40px 20px;
  }
}

/* header-style-1 */
.site-header.header-style-1 .header-nav .nav > li > a,
.site-header.header-style-1 .is-fixed .header-nav .nav > li > a {
  padding: 19px 12px 19px 12px;
}
.site-header.header-style-1 .is-fixed .extra-nav,
.site-header.header-style-1 .extra-nav {
  padding: 16px 0 16px 0;
}
.site-header.header-style-1 .is-fixed .logo-header,
.site-header.header-style-1 .logo-header {
  height: 60px;
}
.site-header.header-style-1 .site-button-link.white {
  color: #fff;
}
.site-header.header-style-1 .site-button {
  color: #fff;
}
.site-header.header-style-1 .navbar-toggler {
  margin: 23px 0 20px 10px;
}
.site-header.header-style-1 .logo-header,
.site-header.header-style-1 .logo-header img {
  max-width: 150px;
}
@media only screen and (max-width: 991px) {
  .site-header.header-style-1 .header-nav .nav > li > a,
  .site-header.header-style-1 .is-fixed .header-nav .nav > li > a {
    padding: 10px 20px 10px 20px;
  }
  .site-header.header-style-1.mo-left .header-nav .logo-header img {
    width: 150px;
  }
  .site-header.header-style-1.mo-left .header-nav .logo-header {
    padding: 10px 20px;
    height: auto;
  }
  .site-header.header-style-1.mo-left .header-nav .nav > li > .sub-menu,
  .site-header.header-style-1.mo-left .header-nav .nav > li > .mega-menu {
    box-shadow: none;
  }
  .site-header.header-style-1 [class*='bg-'] .header-nav .nav > li.active > a,
  .site-header.header-style-1 [class*='bg-'] .header-nav .nav > li > a,
  .site-header.header-style-1
    [class*='bg-']
    .header-nav
    .nav
    > li.current-menu-item
    > a {
    color: #273272;
  }
  .site-header.header-style-1 .header-nav .nav > li .sub-menu li a {
    color: #101042;
  }
}

/** RADIAL PROGRESS **/
.arrow {
  border: 2em solid transparent;
  height: 0;
  margin: 50vh auto;
  width: 0;
}
.arrow.down {
  border-bottom: none;
  border-top-color: #e5e5e5;
}
.arrow.up {
  border-bottom-color: #e5e5e5;
  border-top: none;
}
svg.radial-progress {
  height: auto;
  max-width: 200px;
  padding: 1em;
  transform: rotate(-90deg);
  width: 100%;
}
svg.radial-progress circle {
  fill: rgba(0, 0, 0, 0);
  stroke: #fff;
  stroke-dashoffset: 219.91148575129; /* Circumference */
  stroke-width: 10;
}
svg.radial-progress circle.incomplete {
  opacity: 0.25;
}
svg.radial-progress circle.complete {
  stroke-dasharray: 219.91148575129; /* Circumference */
}
svg.radial-progress text {
  fill: #000;
  font: 400 1em/1 'Oswald', sans-serif;
  text-anchor: middle;
}
/*** COLORS ***/
/* Primary */
svg.radial-progress:nth-of-type(6n + 1) circle {
  stroke: #6cc000;
}

/* Banner Curv */
.rubik h1,
.rubik h2,
.rubik h3,
.rubik h4,
.rubik h5,
.rubik h6,
.rubik {
  font-family: 'Rubik', sans-serif;
}
.banner-curv:after {
  content: '';
  background-image: url(../images/curv.png);
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  z-index: 9;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: 100%;
}
.tab-bx,
.site-button.btn-icon i,
.footer-gradient,
.overlay-primary.gradient:after,
.tp-overlay-pera,
.overlay-primary.testimonial-curv-bx:after,
.about-progress .progress-bar {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#184bd7+0,6c00ae+100 */
  background: rgb(24, 75, 215); /* Old browsers */
  background: -moz-linear-gradient(
    45deg,
    rgba(24, 75, 215, 0.9) 0%,
    rgba(108, 0, 174, 0.9) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    45deg,
    rgba(24, 75, 215, 0.9) 0%,
    rgba(108, 0, 174, 0.9) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    45deg,
    rgba(24, 75, 215, 0.9) 0%,
    rgba(108, 0, 174, 0.9) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#184bd7', endColorstr='#6c00ae',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}
.overlay-primary.testimonial-curv-bx:after {
  opacity: 0.9;
}
.overlay-primary.testimonial-curv-bx:before {
  background-image: url(../images/background/bg2.png);
  background-position: top;
  content: '';
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  background-repeat: no-repeat;
}
.footer-gradient {
  position: relative;
}
.footer-gradient .footer-top,
.footer-gradient .footer-bottom {
  background-color: rgba(0, 0, 0, 0);
}
.footer-content-link {
  padding: 130px 30px 0;
}
.testimonial-curv-bx .testimonial-9 {
  background-color: #fff;
  padding: 0px 30px 30px;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
  text-align: center;
  border-radius: 50px;
  margin-top: 50px;
}
.testimonial-curv-bx .testimonial-9 .testimonial-pic {
  margin-bottom: 0px;
  margin-top: -50px;
  box-shadow: 0px 4px 8px 0 rgba(0, 0, 0, 0.1);
}
.testimonial-curv-bx .testimonial-two-dots-bx .item {
  padding: 15px 25px 25px 25px;
}
.testimonial-curv-bx .testimonial-text p {
  color: #000;
  font-size: 18px;
  font-weight: 300;
  font-family: 'Rubik', sans-serif;
}
.testimonial-curv-bx .testimonial-name {
  font-size: 22px;
  text-transform: capitalize;
  font-family: 'Rubik', sans-serif;
  font-weight: 500;
}
.testimonial-curv-bx .testimonial-9 .testimonial-position {
  text-transform: capitalize;
  font-family: 'Rubik', sans-serif;
  color: #888;
  font-size: 16px;
  font-style: inherit;
  margin-bottom: 10px;
}
.site-button.btn-icon:after {
  content: none;
}
.site-button.btn-icon {
  padding: 18px 70px 18px 35px;
  border-radius: 30px;
  font-size: 16px;
  color: #000;
  font-weight: 400;
  text-transform: capitalize;
  position: relative;
}
.site-button.btn-icon i {
  width: 52px;
  height: 52px;
  position: absolute;
  border-radius: 30px;
  top: 3px;
  color: #fff;
  line-height: 52px;
  font-size: 22px;
  text-align: center !important;
  right: 2px;
}
/* About Box */
.workspace-bx h2,
.contact-form-bx h2,
.pricing-bx h2,
.about-progress h2,
.testimonial-curv-bx h2 {
  font-size: 55px;
  line-height: 65px;
}
.about-progress .progress {
  height: 4px;
  border-radius: 0;
}
.progress-bx {
  position: relative;
  margin-bottom: 30px;
}
.count-box {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 22px;
  color: #000;
}
.img-half-bx {
  background-image: url(../images/pattern/pattern2.png);
  padding: 0 90px 50px 0px;
  text-align: left;
  background-size: 26px;
  margin-left: 40px;
  margin-top: 50px;
}
.img-half-bx img {
  margin-left: -40px;
  margin-top: -50px;
  box-shadow: 0 0 35px 0 rgba(40, 0, 69, 0.3);
}
.br-top:before {
  height: 70px;
  position: absolute;
  content: '';
  top: 0;
  width: 100%;
  background-image: url(../images/curv2.png);
  z-index: 1;
  display: block;
  background-repeat: repeat-x;
  background-position: top;
}
/* Tab Market */
.tab-bx {
  padding-top: 0;
}
.tab-market ul li a i,
.tab-market ul li a span {
  width: 100%;
  display: block;
}
.tab-market ul {
  border-bottom: 0px;
  background-color: #fff;
  float: none;
  display: inline-block;
  width: auto;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  z-index: 2;
  position: relative;
}
.tab-market ul li {
  display: inline-block;
  vertical-align: middle;
}
.tab-market ul li a i {
  font-size: 40px;
  margin-bottom: 15px;
}
.tab-market ul li a span {
}
.tab-market ul li a {
  text-align: center;
  font-size: 18px;
  padding: 20px 30px 16px 30px;
  display: block;
  background: #fff;
  position: relative;
  overflow: hidden;
}
.tab-market ul li:first-child a {
  -webkit-border-radius: 20px 0 0 20px;
  -moz-border-radius: 20px 0 0 20px;
  border-radius: 20px 0 0 20px;
}
.tab-market ul li:last-child a {
  -webkit-border-radius: 0 20px 20px 0;
  -moz-border-radius: 0 20px 20px 0;
  border-radius: 0 20px 20px 0;
}
.tab-market ul li a.active {
  transform: scale(1.1);
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
  z-index: 3;
}
.tab-market ul li a:after {
  content: '';
  position: absolute;
  width: 0;
  left: 50%;
  height: 4px;
  background: #490bb8;
  left: 0px;
  top: 0;
  transition: all 0.5s;
}
.tab-market ul li a.active:after {
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
}
.pricing-bx .pricingtable-wrapper.style1 {
  background-color: #fff;
  border: 0;
  border-radius: 40px;
}
.pricing-bx .pricingtable-wrapper.style1.active {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#184bd7+0,6c00ae+100 */
  background: rgb(24, 75, 215); /* Old browsers */
  background: -moz-linear-gradient(
    45deg,
    rgba(24, 75, 215, 0.9) 0%,
    rgba(108, 0, 174, 0.9) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    45deg,
    rgba(24, 75, 215, 0.9) 0%,
    rgba(108, 0, 174, 0.9) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    45deg,
    rgba(24, 75, 215, 0.9) 0%,
    rgba(108, 0, 174, 0.9) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#184bd7', endColorstr='#6c00ae',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
  color: #fff;
}
.pricing-bx .pricingtable-wrapper.style1.active .pricingtable-bx,
.pricing-bx .pricingtable-wrapper.style1.active h4,
.pricing-bx .pricingtable-wrapper.style1.active .pricingtable-icon i,
.pricing-bx .pricingtable-wrapper.style1.active .pricingtable-features li i,
.pricing-bx .pricingtable-wrapper.style1.active .pricingtable-price,
.pricing-bx .pricingtable-wrapper.style1.active .pricingtable-features li {
  background-color: rgba(0, 0, 0, 0);
  color: #fff;
}
.pricing-bx .pricingtable-wrapper.style1.right {
  border-radius: 0 30px 30px 0px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
}
.pricing-bx .pricingtable-wrapper.style1.left {
  border-radius: 30px 0 0px 30px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
}
/* Contact Form Bx */
.contact-form-bx .form-control {
  background-color: transparent;
  border-width: 0 0 0px 0;
  border-bottom-color: #771cb8;
  padding: 0;
  font-size: 16px;
  font-weight: 400;
  color: #000;
  position: relative;
  padding: 25px 0;
}
.contact-form-bx textarea.form-control {
  height: 207px;
}
.contact-form-bx label {
  position: absolute;
  font-size: 16px;
  font-weight: 400;
  color: #000;
  top: 14px;
  left: 20px;
  transition: all 0.5s;
}
.contact-form-bx .focused label {
  font-size: 12px;
  top: -10px;
  left: 0;
  color: #707070;
}

.contact-form-bx .input-group:after {
  width: 100%;
  height: 3px;
  content: '';
  border-radius: 4px;
  box-shadow: 10px 10px 20px 0 rgba(0, 7, 66, 0.75);
  bottom: 0;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#184bd7+0,6c00ae+100 */
  background: rgb(24, 75, 215); /* Old browsers */
  background: -moz-linear-gradient(
    45deg,
    rgba(24, 75, 215, 0.9) 0%,
    rgba(108, 0, 174, 0.9) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    45deg,
    rgba(24, 75, 215, 0.9) 0%,
    rgba(108, 0, 174, 0.9) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    45deg,
    rgba(24, 75, 215, 0.9) 0%,
    rgba(108, 0, 174, 0.9) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#184bd7', endColorstr='#6c00ae',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}
.contact-form-bx .form-control::-moz-placeholder {
  color: #000;
}
.subscribe-bx .contact-form-bx .input-group:after {
  background: #fff;
}
.subscribe-bx .contact-form-bx label,
.subscribe-bx .contact-form-bx .form-control {
  color: #fff;
}
.workspace-bx .portfolio-box .dlab-media {
  border-radius: 10px;
  box-shadow: 0 0 15px 0 rgba(4, 0, 46, 0.2);
}
.workspace-bx .dlab-img-overlay1:before {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#184bd7+0,6c00ae+100 */
  background: rgb(24, 75, 215); /* Old browsers */
  background: -moz-linear-gradient(
    45deg,
    rgba(24, 75, 215, 0.9) 0%,
    rgba(108, 0, 174, 0.9) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    45deg,
    rgba(24, 75, 215, 0.9) 0%,
    rgba(108, 0, 174, 0.9) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    45deg,
    rgba(24, 75, 215, 0.9) 0%,
    rgba(108, 0, 174, 0.9) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#184bd7', endColorstr='#6c00ae',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
  opacity: 0.1;
}
.workspace-bx .dlab-media:hover .dlab-img-overlay1:before {
  opacity: 0.9;
}

.sort-title-bx.text-white {
  color: #fff;
}
.sort-title-bx {
  font-family: 'Rubik', sans-serif;
  text-transform: uppercase;
  font-weight: 500;
  color: #000;
  position: absolute;
  width: 100%;
  top: 12px;
  max-width: 1140px;
  left: 50%;
  transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  z-index: 1;
}
.sort-title-bx:after {
  text-transform: capitalize;
  content: attr(data-name);
  opacity: 0.1;
  font-size: 65px;
  font-weight: 600;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
}
/*  */
.arrow-bx {
  width: 80px;
  height: 55px;
  background-image: url(../images/arrow-1.png);
  background-size: 100%;
  background-repeat: no-repeat;
}
.header-gradient .header-nav .nav > li:hover > a,
.header-gradient .header-nav .nav > li.active > a {
  color: #fff;
}
@media only screen and (max-width: 1400px) {
  .rev-slider-full .site-button.btn-icon i {
    display: none;
  }
  .rev-slider-full .site-button.btn-icon {
    padding: 15px 25px 15px 25px;
  }
}
@media only screen and (max-width: 991px) {
  .header-gradient .header-nav .nav > li:hover > a,
  .header-gradient .header-nav .nav > li.active > a {
    color: #000;
  }
}
@media only screen and (max-width: 576px) {
  .rev-slider-full .site-button.btn-icon i {
    display: none;
  }
  .rev-slider-full .site-button.btn-icon {
    padding: 15px 25px 15px 25px;
  }
}

@media only screen and (max-width: 768px) {
  .tab-market ul li a {
    font-size: 16px;
    padding: 20px 20px 16px 20px;
  }
}
@media only screen and (max-width: 767px) {
  .tab-market ul li a {
    font-size: 13px;
    padding: 20px 10px 16px 10px;
  }
  .tab-market ul li a i {
    font-size: 30px;
  }
  .workspace-bx h2,
  .contact-form-bx h2,
  .pricing-bx h2,
  .about-progress h2,
  .testimonial-curv-bx h2 {
    font-size: 35px;
    line-height: 40px;
  }
  .footer-bottom .text-left,
  .footer-bottom .text-right {
    text-align: center !important;
  }
  .footer-content-link {
    padding: 50px 0 0;
  }
  .pricing-bx .pricingtable-wrapper.style1.right,
  .pricing-bx .pricingtable-wrapper.style1.left {
    border-radius: 30px;
  }
  .pricingtable-wrapper.style1.active {
    transform: translateY(0);
    -ms-transform: translateY(0);
    -moz-transform: translateY(0);
    -webkit-transform: translateY(0);
    -o-transform: translateY(0);
    margin-bottom: 30px;
  }
  .dlab-bnr-inr .breadcrumb-row ul {
    margin-bottom: 20px;
  }
}
@media only screen and (max-width: 576px) {
  .tab-market ul li a {
    border-radius: 0 !important;
  }
  .tab-market ul li {
    float: left;
    width: 50%;
  }
  .workspace-bx h2,
  .contact-form-bx h2,
  .pricing-bx h2,
  .about-progress h2,
  .testimonial-curv-bx h2 {
    font-size: 30px;
    line-height: 35px;
  }
  .img-half-bx {
    padding: 0 0 50px;
  }
  .testimonial-curv-bx .testimonial-two-dots-bx .item {
    padding: 50px 10px 10px 10px;
  }
  .testimonial-curv-bx .testimonial-9 {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 0px 10px 10px;
    border-radius: 20px;
  }
}
/* Home 5 */
.header-ind.header .top-bar.bg-dark {
  background-color: #020d26 !important;
  padding: 10px 0;
}
.header-ind.header .top-bar.bg-dark .dlab-topbar-left ul li,
.header-ind.header .top-bar.bg-dark .dlab-topbar-right ul li {
  color: #d8e0f3;
  opacity: 1;
}
.header-ind .header-nav .nav > li > a {
  padding: 28px 12px;
}
.header-ind .extra-nav {
  padding-top: 18px;
  padding-bottom: 18px;
}
.header-ind .logo-header {
  height: 80px;
}

.rubik-font h1,
.rubik-font h2,
.rubik-font h3,
.rubik-font h4,
.rubik-font h5,
.rubik-font h6,
.rubik-font p,
.rubik-font {
  font-family: rubik;
}

/* service box */
.ind-ser-bx {
  padding: 30px 30px 80px 30px;
  border-radius: 4px;
  box-shadow: 0 6px 20px 0 rgba(3, 0, 24, 0.15);
  background-color: #fff;
  transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  margin-top: -60px;
}
.ind-ser-bx:hover {
  transform: translateY(-10px);
  -ms-transform: translateY(-10px);
  -o-transform: translateY(-10px);
  -moz-transform: translateY(-10px);
  -webkit-transform: translateY(-10px);
}
.ind-ser-bx:hover .btn-block {
  background-color: var(--bg-color);
}
.ind-ser-bx .dlab-tilte {
  font-weight: 400;
  margin-bottom: 10px;
}
.ind-ser-bx .btn-block {
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 16px 30px;
  border-radius: 0 0 4px 4px;
  font-size: 18px;
  font-weight: 400;
  background-color: #020d26;
}

.industry-service {
  padding-bottom: 80px;
}
.footer-white .subscribe-form input {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 4px 0px 0px 4px !important;
  font-size: 15px;
  color: #000;
}
.footer-white .subscribe-form .form-control::-moz-placeholder {
  color: #000;
}
.footer-white .subscribe-form .form-control:-moz-placeholder {
  color: #000;
}
.footer-white .subscribe-form .form-control:-ms-input-placeholder {
  color: #000;
}
.footer-white .subscribe-form .form-control::-webkit-input-placeholder {
  color: #000;
}

.bg-blue-light {
  background-color: #f5f5fa;
}
/* explore-projects */
.explore-projects .item {
  padding: 15px;
}
.explore-projects .dlab-box {
  box-shadow: 0 0 15px 0 rgba(3, 0, 24, 0.15);
  border-radius: 4px;
  overflow: hidden;
}
.explore-projects .dlab-info {
  padding: 30px 30px 60px 30px;
}
.explore-projects .dlab-box .dlab-title {
  font-weight: 500;
}
.explore-projects .dlab-box .btn-block {
  position: absolute;
  bottom: 0;
  border-radius: 0px 0px 4px 0px;
  margin: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  font-weight: 400;
  padding: 15px 30px;
  font-size: 14px;
}
.explore-projects .dlab-box:hover .btn-block {
  color: #fff;
  background-color: var(--bg-color);
}
.explore-carousel {
  margin-top: -15px;
}

/* ind client */
.ind-client .testimonial-8 .testimonial-text {
  padding: 30px;
  color: #000;
}
.ind-client .testimonial-box.owl-theme .owl-dots.disabled {
  margin-top: 0;
}

/* Contact Form Bx */
.support-box-form .form-control {
  background-color: transparent;
  border-width: 0 0 0px 0;
  border-bottom-color: #771cb8;
  padding: 0;
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  position: relative;
  padding: 25px 0;
}
.support-box-form textarea.form-control {
  height: 207px;
}
.support-box-form label {
  position: absolute;
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  top: 14px;
  left: 0;
  transition: all 0.5s;
}
.support-box-form .focused label {
  font-size: 12px;
  top: -10px;
  left: 0;
  color: #fff;
}
.support-box-form .input-group:after {
  width: 100%;
  height: 2px;
  content: '';
  bottom: 0;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#184bd7+0,6c00ae+100 */
  background: rgba(255, 255, 255, 0.2);
}
.support-box-form .form-control::-moz-placeholder {
  color: #fff;
}
.support-form {
  padding: 40px 25px;
}
.support-box-form {
  border-radius: 4px;
  box-shadow: 0 0 20px 0 rgba(23, 7, 0, 0.1);
  overflow: hidden;
}
.our-support {
  position: relative;
  z-index: 1;
}
.our-support:before {
  content: '';
  width: 100%;
  background-color: #fff;
  height: 250px;
  top: 0;
  display: block;
  z-index: -1;
  position: absolute;
}

/* our projects */
.our-projects .row {
  height: 700px;
}
.our-projects .ind-ser-info-bx {
  border-right: 1px solid rgba(255, 255, 255, 0.1);
}
.ind-service-info {
  padding: 20px;
  position: relative;
  width: 100%;
  overflow: hidden;
  transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
}
.ind-service-info:hover {
  background-color: var(--bg-color);
}
.ind-service-info-in h2 {
  font-size: 45px;
  font-weight: 700;
  margin-bottom: 10px;
}
.ind-service-info-in h2 span {
  font-size: 24px;
  font-weight: 400;
  display: block;
}
.ind-service-info-in {
  position: absolute;
  top: 100%;
  width: 100%;
  padding: 20px;
  left: 0;
  transform: translateY(-100%);
  transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
}
.ind-service-info .site-button.outline {
  padding: 25px 30px;
  color: #fff;
  font-size: 18px;
  font-weight: 400;
  position: absolute;
  bottom: -150px;
  left: 0;
  border: 0;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 0;
  transition: all 1s;
  -moz-transition: all 1s;
  -ms-transition: all 1s;
  -webkit-transition: all 1s;
  -o-transition: all 1s;
}
.ind-service-info .site-button.outline:hover {
  color: #fff;
}
.ind-service-info-in p {
  display: none !important;
  transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  opacity: 0;
}
.ind-service-info > span {
  font-size: 85px;
  opacity: 0.1;
  font-weight: 700;
  transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
}
/* hover */
.ind-service-info:hover > span {
  opacity: 0.3;
}
.ind-service-info:hover .ind-service-info-in p {
  display: flex !important;
  transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  opacity: 1;
}
.ind-service-info:hover .ind-service-info-in {
  top: 50%;
  width: 100%;
  padding: 20px;
  left: 0;
  transform: translateY(-50%);
  transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
}

.ind-service-info:hover .site-button.outline {
  bottom: 0;
}
/* under construction */
.under-construction {
  background-color: #f0f5f9;
}
.under-construction .header-ind {
  padding-top: 50px;
}
.under-construction .header-ind .logo-header {
  max-width: 100%;
}
.under-construction h2 {
  font-size: 100px;
  font-family: rubik;
  line-height: 80px;
  text-transform: uppercase;
  color: #62aad8;
  display: block;
  clear: both;
}
.under-construction h2 span {
  font-size: 45px;
  color: #eab847;
}
.under-construction .header-ind .logo-header {
  height: auto;
  width: 100%;
}
@media only screen and (max-width: 1200px) {
  .ind-ser-bx .dlab-tilte {
    font-size: 24px;
  }
}
@media only screen and (max-width: 991px) {
  .header-ind .navbar-toggler {
    margin: 30px 0 30px 15px;
  }
  .header-ind .header-nav .nav > li > a {
    padding: 10px 15px;
  }
  .header-ind .header-nav .nav > li.active > a,
  .header-ind .header-nav .nav > li:hover,
  .header-ind .header-nav .nav > li.active > a,
  .header-ind .header-nav .nav > li:hover > a {
    color: #000;
  }
  .ind-service-info-in h2 {
    font-size: 30px;
  }
  .section-head .font-40,
  .box-title.font-40 {
    font-size: 30px;
    line-height: 40px;
  }
  .header-nav .nav > li.active > a,
  .header-nav .nav > li:hover > a {
    color: #000;
  }
  .under-construction h2 {
    font-size: 70px;
    line-height: 50px;
  }
  .under-construction h2 span {
    font-size: 28px;
  }
}
@media only screen and (max-width: 768px) {
  .ind-service-info-in h2 {
    font-size: 30px;
    line-height: 36px;
  }
  .ind-service-info-in h2 span {
    font-size: 20px;
  }
  .section-head .box-title {
    font-size: 30px;
    line-height: 40px;
  }
  .add-to-call {
    text-align: center;
  }
}
@media only screen and (max-width: 767px) {
  .ind-ser-bx {
    margin-top: 30px;
  }
  .add-to-call h2 {
    font-size: 24px;
    line-height: 36px;
  }
  .header-ind .logo-header {
    height: 60px;
  }
  .header-ind .navbar-toggler {
    margin: 20px 0 20px 10px;
  }
  .header-ind .extra-nav {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .our-projects .row {
    min-height: 1300px;
  }
  .support-form {
    padding: 30px 5px;
  }
}
@media only screen and (max-width: 576px) {
  .under-construction h2 {
    font-size: 38px;
    line-height: 35px;
  }
  .under-construction h2 span {
    font-size: 20px;
  }
  .under-construction .header-ind .logo-header {
    margin-bottom: 10px;
  }
  .under-construction .header-ind {
    padding-top: 30px;
  }
}

/* Home Page 6 */
.header-seo.header .header-nav .nav > li > a {
  color: #4a4a4a;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
}

.header-seo .dlab-topbar-left ul li,
.header-seo .dlab-topbar-right ul li {
  color: #7a7a7a;
}
.header-seo.header-transparent .navbar-toggler span {
  background-color: #000;
}
.header-seo.header-transparent .is-fixed .main-bar {
  background-color: rgba(255, 255, 255, 0.95);
}

/* About Bx2 Content */
.about-bx2-content {
  width: 25;
}
.title-bx h2 {
  color: #58468c;
  font-size: 45px;
  font-family: 'Poppins', sans-serif;
  line-height: 55px;
  font-weight: 500;
}
.title-bx .dlab-separator {
  height: 7px;
  width: 82px;
  border-radius: 10px;
}
.btn-long {
  padding-left: 40px;
  padding-right: 40px;
}

.bubbles-bx {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  text-align: center;
  color: #fff;
  width: 220px;
  height: 220px;
  display: flex;
  text-align: center;
  align-items: center;
}
.bubbles-bx.bubbles-bx3 {
  width: 200px;
  height: 190px;
  margin-bottom: 20px;
  margin-left: -50px;
}
.bubbles-bx.bubbles-bx2 {
  width: 200px;
  height: 210px;
}
.bubbles-bx.bubbles-bx4 {
  width: 170px;
  height: 195px;
}
.bubbles-bx.bubbles-bx5 {
  width: 170px;
  height: 170px;
}
.bubbles-bx .bubinbox {
  width: 100%;
}
.bubbles-bx .bubinbox .dlab-tilte {
  font-size: 15px;
  color: #fff;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 0;
}
.bubbles-bx .icon-lg img {
  height: 60px;
}

/*  Project Gallery  */
.product-gallery-bx .owl-nav {
  margin-top: 0;
  text-align: center;
  position: absolute;
  top: -65px;
}
.product-gallery-bx .owl-prev,
.product-gallery-bx .owl-next {
  background-color: transparent;
  width: 70px;
  padding: 0;
  position: relative;
  transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  -webkit-transition: all 0.5s;
}
.product-gallery .owl-prev,
.product-gallery .owl-next {
  color: #fff !important;
}
.product-gallery-bx .owl-prev:hover {
  transform: translateX(-20px);
  -webkit-transform: translateX(-20px);
  transform: translateX(-20px);
  transform: translateX(-20px);
  transform: translateX(-20px);
  opacity: 0.7;
  background: none !important;
}
.product-gallery-bx .owl-next:hover {
  transform: translateX(20px);
  -moz-transform: translateX(20px);
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  -o-transform: translateX(20px);
  opacity: 0.7;
  background: none !important;
}
.product-gallery-bx .owl-prev:focus,
.product-gallery-bx .owl-next:focus {
  outline: none;
}
.our-product-gallery .section-head {
  margin-bottom: 100px;
}
.product-gallery-bx .owl-stage-outer {
  border-radius: 10px 0 0px 10px;
}
.product-gallery-bx .overlay-icon {
  text-align: center;
}
.product-gallery-bx .overlay-icon a i {
  background-color: transparent;
  color: #fff;
  font-size: 30px;
}
/* Product */
.product-box {
  display: flex;
  align-items: center;
  background: #fff;
  box-shadow: 0 0 20px 0 rgba(9, 0, 93, 0.1);
  border-radius: 20px;
  overflow: hidden;
  width: 100%;
  margin-bottom: 30px;
}
.product-box .product-img,
.product-box .product-details {
  width: 50%;
}
.product-box .rating-star {
  margin: 10px 0 0 0;
  padding: 0;
  list-style: none;
}
.product-box .rating-star li {
  font-size: 14px;
  display: inline-block;
  color: #dedede;
  font-size: 13px;
}
.product-box .rating-star li.active {
  color: #ff8a73;
}
.product-box .product-details {
  padding: 20px 10px 20px 15px;
}

.product-box .product-details .product-title {
  font-family: poppins;
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  margin-bottom: 5px;
}
.product-box .product-details .product-title a {
  color: #58468c;
}
.product-box .product-details .product-tage {
  background-color: #58468c;
  color: #fff;
  font-size: 12px;
  padding: 2px 5px;
  border-radius: 2px;
}
.product-box .site-button {
  font-weight: 400;
}
.product-price h4 {
  font-size: 20px;
  font-weight: 500;
  font-family: poppins;
  color: #ff8a73;
  margin-bottom: 10px;
}
.product-right .product-img {
  text-align: right;
}
.product-lg.product-box .product-details {
  padding: 20px 10px 20px 25px;
}
.product-lg.product-box .product-details .product-title {
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 10px;
}
.product-sm.product-box .product-img {
  width: 35%;
}
.product-sm.product-box .product-details {
  width: 65%;
}
.product-sm.product-box .product-details .product-title {
  font-size: 14px;
  line-height: 20px;
}
.product-sm.product-box .product-details .product-tage {
  font-size: 10px;
}
.product-sm.product-box .rating-star li {
  font-size: 11px;
}
.product-sm.product-box .rating-star {
  margin-top: 0;
}
.product-sm.product-price h4 {
  font-size: 16px;
  margin-bottom: 5px;
}
.product-box .product-img {
  transition: all 0.5s;
}
.product-box:hover .product-img {
  transform: scale(1.1);
}
.business-product {
}
/* Our Featured */
.our-featured .section-head {
  margin: 100px 0;
}
/* Blog Box */
.blog-bx .post-title {
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 15px;
  margin-top: 0;
}
.blog-bx .post-title a {
  color: #58468c;
}

.blog-bx .dlab-post-meta ul li {
  font-weight: 400;
  font-family: 'Poppins', sans-serif;
  color: #959595;
  font-size: 12px;
  margin-right: 10px;
}
.blog-bx .dlab-post-meta ul li a {
  color: #959595;
}
.blog-bx .dlab-post-meta li::after {
  content: none;
}
.blog-bx .dlab-post-text {
  margin-bottom: 10px;
}
.blog-bx .dlab-post-text p {
  font-weight: 300;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  color: #000;
}
.blog-bx .site-button-link {
  padding: 0;
  font-size: 24px;
}
.blog-bx .site-button-link i {
  margin-left: 0;
}

.blog-bx .blog-number {
  font-weight: 400;
  font-family: 'Poppins', sans-serif;
  font-size: 35px;
  color: #ff8a73;
  opacity: 0.5;
}
.blog-bx.blog-img-bottom .dlab-post-media {
  margin-top: 50px;
}
.blog-bx .dlab-post-media {
  margin-bottom: 50px;
}
.slide-img-curve {
  -webkit-mask-image: url(../images/main-slider/slide09.png);
  mask-image: url(../images/main-slider/slide09.png);
  -webkit-mask-position: center center;
  mask-position: center center;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-size: 100%;
  mask-size: 100%;
}
.blog-curve-img img {
  -webkit-mask-image: url(../images/blog/latest-blog/blog-bg2.png);
  mask-image: url(../images/blog/latest-blog/blog-bg2.png);
  -webkit-mask-position: center center;
  mask-position: center center;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-size: 100%;
  mask-size: 100%;
}
.blog-curve-img a {
  display: block;
  position: relative;
  z-index: 1;
}
.blog-curve-img a:after {
  content: '';
  background-image: url(../images/blog/latest-blog/blog-bg1.png);
  background-size: 100%;
  background-position: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
/* Newsletter Box */
.newsletter-bx .dzSubscribe {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+26,fff4f1+81 */
  background: rgb(255, 255, 255); /* Old browsers */
  background: -moz-linear-gradient(
    top,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 26%,
    rgba(255, 244, 241, 1) 81%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 26%,
    rgba(255, 244, 241, 1) 81%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 26%,
    rgba(255, 244, 241, 1) 81%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#fff4f1',GradientType=0 ); /* IE6-9 */
  border-radius: 30px;
  padding: 30px 40px;
}
.newsletter-bx .contact-form-bx .input-group::after {
  height: 1px;
  background: #ff8a73;
  box-shadow: none;
  width: 96%;
  bottom: 0px;
  position: absolute;
}
.newsletter-bx .form-group {
  display: flex;
  margin-bottom: 0;
}
.newsletter-bx .form-group .site-button {
  padding: 10px 40px;
  font-weight: 400;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
}
.newsletter-bx .contact-form-bx .form-control {
  padding: 20px 0;
}
.newsletter-bx .contact-form-bx label {
  font-size: 14px;
  color: #787878;
  top: 9px;
  left: 15px;
  font-family: 'Poppins', sans-serif;
}
.newsletter-bx .contact-form-bx label {
  font-size: 14px;
  color: #787878;
  top: 9px;
  left: 15px;
  font-family: 'Poppins', sans-serif;
}
.newsletter-bx .contact-form-bx .focused label {
  font-size: 12px;
  color: #787878;
  top: -5px;
  left: 0px;
}
/* Team Curve Box */
.team-curve-bx .dlab-media img {
  -webkit-mask-image: url(../images/our-team/team-bg1.png);
  mask-image: url(../images/our-team/team-bg1.png);
  -webkit-mask-position: center center;
  mask-position: center center;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  box-sizing: border-box;
  padding: 0 10%;
}
.team-curve-bx .dlab-media {
  position: relative;
  z-index: 1;
}
.team-curve-bx .dlab-media:after {
  content: '';
  background-image: url(../images/our-team/team-bg2.png);
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
.team-curve-bx .title a {
  color: #ff8a73;
}
.team-curve-bx .title {
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
}
.business-contact .contact-form-bx {
  max-width: 900px;
  margin: auto;
}
.business-contact .form-group .site-button {
  padding: 10px 40px;
  font-weight: 400;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
}
.business-contact .contact-form-bx .input-group::after {
  height: 1px;
  background: #ff8a73;
  box-shadow: none;
  width: 96%;
  bottom: 0px;
  position: absolute;
}
.business-contact .contact-form-bx label {
  font-size: 14px;
  color: #787878;
  top: 9px;
  left: 15px;
  font-family: 'Poppins', sans-serif;
}
.business-contact .contact-form-bx .focused label {
  font-size: 12px;
  color: #787878;
  top: 0px;
  left: 0px;
}
.business-contact .contact-form-bx textarea.form-control {
  height: 100px;
}
.app-btn-bx .site-button,
.business-contact .site-button {
  padding: 10px 40px;
  font-weight: 400;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
}
.app-btn-bx a i {
  margin-right: 10px;
  font-size: 24px;
}
.business-contact {
  padding-top: 190px;
  padding-bottom: 150px;
}
/* Business Footer */
.business-footer {
  background-image: url(../images/background/bg20.jpg);
  background-position: top center;
  background-color: #fff;
}
.business-footer .footer-top {
  /* padding-top: 100px; */
}
.business-footer .footer-top,
.business-footer .footer-bottom {
  background-color: rgba(0, 0, 0, 0);
}
.business-footer .footer-top h5 {
  font-family: 'Poppins', sans-serif;
  color: #ff8a73;
  font-size: 20px;
  text-transform: capitalize;
  font-weight: 500;
}
footer.business-footer.footer-white.site-footer .widget_services ul li a,
footer.business-footer.footer-white p,
footer.business-footer.footer-white strong,
footer.business-footer.footer-white b,
footer.business-footer.footer-white,
footer.business-footer.footer-white .footer-bottom,
footer.business-footer.footer-white .footer-bottom a {
  color: #8d8d8d;
}
/* Testimonial Box */
/* Flip Box ============== */
.flip-bx {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-perspective: 1000px;
  perspective: 1000px;
}
.flip-bx .front,
.flip-bx .back {
  background-size: cover;
  background-position: center;
  -webkit-transition: -webkit-transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
  transition: -webkit-transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
  -o-transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
  transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
  transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1),
    -webkit-transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  text-align: center;
  min-height: 280px;
  height: auto;
  border-radius: 4px;
  color: #fff;
}
.flip-bx .back {
  background: #ff8a73;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}
.flip-bx .front {
  background: rgb(255, 255, 255);
  background: -moz-linear-gradient(
    top,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 26%,
    rgba(255, 244, 241, 1) 81%
  );
  background: -webkit-linear-gradient(
    top,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 26%,
    rgba(255, 244, 241, 1) 81%
  );
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 26%,
    rgba(255, 244, 241, 1) 81%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#fff4f1',GradientType=0 );
  -webkit-transform: rotateY(0deg);
  transform: rotateY(0deg);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}
.flip-bx .front:after {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  content: '';
  display: block;
  opacity: 0.4;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 4px;
}
.flip-bx :hover .front,
.flip-bx :hover .back {
  -webkit-transition: -webkit-transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
  transition: -webkit-transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
  -o-transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
  transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
  transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1),
    -webkit-transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
}
.flip-bx:hover .back {
  -webkit-transform: rotateY(0deg);
  transform: rotateY(0deg);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}
.flip-bx:hover .front {
  -webkit-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}
.flip-bx .inner {
  -webkit-transform: translateY(-50%) translateZ(60px) scale(0.94);
  transform: translateY(-50%) translateZ(60px) scale(0.94);
  top: 50%;
  position: absolute;
  left: 0;
  width: 100%;
  padding: 2rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  outline: 1px solid transparent;
  -webkit-perspective: inherit;
  perspective: inherit;
  z-index: 2;
}
.flip-bx .back .inner p {
  font-size: 16px;
  color: #fff;
}
.flip-bx .back .inner .site-button-link {
  font-size: 16px;
}
.testimonial-flip .front .testimonial-detail strong {
  font-family: 'Poppins', sans-serif;
  color: #ff8a73;
  text-transform: capitalize;
  font-size: 20px;
  font-weight: 500;
}
.testimonial-flip .front .testimonial-detail span {
  color: #707070;
  font-family: 'Poppins', sans-serif;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 500;
}

.testimonial-flip .front .testimonial-pic {
  border: 0 none;
  height: 90px;
  width: 90px;
  border: 5px solid #fff;
  box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.05);
  margin-bottom: 15px;
}

.testimonial-flip .back p {
  font-size: 14px;
}
.testimonial-flip .back .testimonial-detail strong {
  font-family: 'Poppins', sans-serif;
  color: #fff;
  text-transform: capitalize;
  font-size: 20px;
  font-weight: 500;
}
.testimonial-flip .back:after {
  content: '\f10e';
  font-family: FontAwesome;
  font-size: 60px;
  opacity: 0.3;
  bottom: 0px;
  position: absolute;
  right: 25px;
}
.testimonial-flip .back .testimonial-detail span {
  color: #fff;
  opacity: 0.8;
  font-family: 'Poppins', sans-serif;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 500;
}
.feature-img-bx {
  position: relative;
  z-index: 1;
}
.feature-img-bx:after {
  content: '';
  background-image: url(../images/background/bg11.png);
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
.feature-img-bx img {
  -webkit-mask-image: url(../images/background/bg12.png);
  mask-image: url(../images/background/bg12.png);
  -webkit-mask-position: center center;
  mask-position: center center;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-size: 100%;
  mask-size: 100%;
}
.mfp-wrap {
  z-index: 999999;
}
.mfp-bg {
  z-index: 999999;
}
@media only screen and (max-width: 1200px) {
  .our-featured .setResizeMargin,
  .our-product-gallery .setResizeMargin {
    padding-left: 50px;
  }
  .blog-bx .dlab-post-media,
  .blog-bx.blog-img-bottom .dlab-post-media {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .product-sm.product-box .product-img {
    width: 20%;
  }
  .product-sm.product-box .product-details {
    width: 80%;
  }
}
@media only screen and (max-width: 991px) {
  .bubbles-bx.bubbles-bx3 {
    margin-left: -10px;
  }
  .bubbles-bx.bubbles-bx1 {
    margin-bottom: 20px;
  }
  .our-product-gallery {
    background-size: 60% !important;
    background-position: center right !important;
  }
  .our-product-gallery {
  }
  .title-bx h2 {
    color: #58468c;
    font-size: 35px;
    margin-bottom: 0;
  }
  .about-bx2 {
    background-size: 70%;
    padding-bottom: 0;
  }
  .business-product {
    padding-top: 0;
  }
  .business-pro-blog {
  }
  .our-featured {
    padding-top: 0;
  }
  .our-featured .section-head {
    margin: 0;
  }
  .blog-bx .post-title {
    font-size: 20px;
    line-height: 28px;
  }
  .business-blog {
    padding-top: 0;
  }
  .newsletter-bx .title-bx {
    margin-bottom: 20px;
  }
  .newsletter-bx .title-bx br {
    display: none;
  }
  .newsletter-bx {
    padding-top: 0;
  }
  .newsletter-bx .dzSubscribe {
    padding: 30px 15px;
  }
  .business-contact {
    padding-top: 0px;
    padding-bottom: 0px;
    background-size: 100%;
  }
  .business-footer {
    background-size: 100%;
    background-repeat: no-repeat;
  }
  .business-footer .footer-top {
    padding-top: 100px;
  }
}
@media only screen and (max-width: 767px) {
  .about-bx2-content {
    text-align: left !important;
  }
  .title-bx h2 {
    font-size: 28px;
    line-height: 35px;
  }
  .our-product-gallery .section-head {
    margin-bottom: 50px;
  }
  .about-bx2-content {
    margin-top: 20px;
  }
  .blog-post.blog-bx {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
  .our-featured {
    padding-bottom: 50px;
  }
  .blog-bx .post-title {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 5px;
  }
  .newsletter-bx {
    padding-bottom: 40px;
  }
}
@media only screen and (max-width: 576px) {
  .bubbles-bx.bubbles-bx1 {
    width: 150px;
    height: 150px;
  }
  .bubbles-bx.bubbles-bx3 {
    width: 120px;
    height: 120px;
    margin-bottom: 20px;
    margin-left: 0;
    margin-top: 20px;
  }
  .bubbles-bx.bubbles-bx2 {
    width: 140px;
    height: 150px;
  }
  .bubbles-bx.bubbles-bx4 {
    width: 130px;
    height: 145px;
  }
  .bubbles-bx.bubbles-bx5 {
    width: 170px;
    height: 170px;
  }
  .bubbles-bx .icon-lg img {
    height: 35px;
    margin-bottom: 5px;
  }
  .our-featured .setResizeMargin,
  .our-product-gallery .setResizeMargin {
    padding-left: 15px;
  }
  .our-featured .section-head {
    margin-bottom: 30px;
  }
  .newsletter-bx .form-group .site-button {
    padding: 14px 15px;
    font-size: 13px;
  }
  .newsletter-bx .contact-form-bx label {
    left: 0;
  }
  .app-btn-bx a i {
    margin-right: 5px;
    font-size: 18px;
  }
}

/* Home 7 */
.section-head .title-head {
  font-size: 44px;
  font-weight: 700;
  text-transform: uppercase;
}
.section-head .title-small {
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 400;
  margin-bottom: 10px;
  opacity: 0.7;
  letter-spacing: 3px;
}
.list-arrow-right i {
  font-size: 12px;
  margin-right: 10px;
}
.list-arrow-right li {
  font-size: 16px;
  margin-bottom: 5px;
}
.about-1 {
  box-shadow: 0 0 15px 5px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
}
.about-1 .about-scale {
  -moz-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -o-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
  z-index: 9;
}
.about-1 .icon-bx-wraper {
  margin-top: -120px;
}
.about-1 .dez-tilte span {
  display: block;
}
.site-button.long-btn {
  padding: 15px 60px;
}
.our-service .dez-tilte {
  font-size: 17px;
  font-weight: 400;
  letter-spacing: 3px;
  color: #333;
  margin-bottom: 5px;
}

/* Experts */
.experts-img {
  width: 80px;
  border-radius: 50px;
  overflow: hidden;
  margin-right: 20px;
  float: left;
}
.experts-info .experts-name {
  font-weight: 600;
  font-size: 18px;
  text-transform: capitalize;
  margin-bottom: 10px;
  line-height: 18px;
}
.experts-info-bx {
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  margin-bottom: 10px;
}
.experts-service {
  font-weight: 400;
  font-size: 14px;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.8) !important;
  margin-bottom: 0;
}
.experts-box .dez-social-icon {
  display: inline-block;
  margin: 0 -3px;
  padding: 0;
  text-align: center;
  list-style: none;
}
.experts-box .dez-social-icon li {
  display: inline-block;
  margin-right: 5px;
}
.experts-box .dez-social-icon li a {
  font-size: 13px;
  width: 25px;
  height: 25px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 4px;
  line-height: 23px;
}
.experts-box:hover .dez-social-icon li a {
  border: 1px solid rgba(255, 255, 255, 0.2);
  background-color: #fff;
  color: #333;
}
.our-team-bx {
  background-image: url(../images/desktop.png),
    url(../images/pattern/pt/pt1.png);
  background-repeat: no-repeat, repeat;
  background-position: left -30% bottom -50px;
}
@media only screen and (max-width: 1200px) {
  .our-team-bx {
    background-image: url(../images/pattern/pt/pt1.png);
    background-repeat: repeat;
    background-position: bottom -50px;
  }
}

.experts-box p {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 15px;
}
/* Pricing Table */
.pricingtable-wrapper.style3 .pricingtable-features li span {
  float: right;
}
.pricingtable-wrapper.style3 {
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.pricingtable-wrapper.style3 .pricingtable-features li {
  text-align: left;
  padding: 5px 0;
  font-weight: 500;
  color: #000;
}
.pricingtable-wrapper.style3 .pricingtable-head::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 70px;
  left: 0px;
  transform: skewY(-3deg);
  bottom: -10px;
  z-index: -1;
  background-color: inherit;
}
.pricingtable-wrapper.style3 .bg-gray.pricingtable-head::after {
  background-color: #eef1ff;
}
.pricingtable-wrapper.style3 .pricingtable-head {
  padding: 25px 35px 10px;
  position: relative;
  z-index: 1;
}
.pricingtable-wrapper.style3 .pricingtable-features {
  padding: 20px 30px 0;
}
.pricingtable-wrapper.style3 .pricingtable-price .pricingtable-type:before {
  content: unset;
}
.pricingtable-wrapper .pricingtable-price,
.pricingtable-wrapper .pricingtable-title,
.pricingtable-wrapper .pricingtable-features li,
.pricingtable-wrapper .pricingtable-footer,
.pricingtable-wrapper .pricingtable-features {
  border: 0;
  background-color: unset;
}
.pricingtable-wrapper .pricingtable-price .pricingtable-bx span {
  font-size: 24px;
  font-weight: 400;
  margin-right: 5px;
}
.pricingtable-wrapper .pricingtable-price .pricingtable-bx {
  font-weight: 700;
  font-family: poppins;
  font-size: 60px;
  line-height: 60px;
  color: unset;
}
.pricingtable-wrapper.style3 .pricingtable-price .pricingtable-type {
  font-weight: 500;
  text-transform: capitalize;
  display: block;
}
.pricingtable-wrapper.style3 .pricingtable-content {
  font-weight: 500;
  padding-top: 20px;
  font-size: 14px;
  line-height: 24px;
  color: #333;
}
.pricingtable-wrapper.style3 .bg-gray {
  background-color: #eef1ff;
}
.pricingtable-wrapper.style3 .pricingtable-title h2 {
  font-size: 22px;
  font-weight: 700;
  color: unset;
  text-transform: capitalize;
}
.pricingtable-wrapper.style3 .pricingtable-title p {
  font-size: 14px;
  font-weight: 400;
  color: unset;
  text-transform: uppercase;
  color: #000;
  letter-spacing: 2px;
  opacity: 0.5;
}
.pricingtable-wrapper.style3 .bg-primary .pricingtable-content,
.pricingtable-wrapper.style3 .bg-primary .pricingtable-title p {
  color: #fff;
}
.pricingtable-wrapper.style3 .pricingtable-price {
  padding: 0;
}
.pricingtable-wrapper-box {
  z-index: 9;
  position: relative;
}
.pricingtable-wrapper-box [class*='col-']:first-child .style4 {
  border-radius: 30px 0 0 30px;
}
.pricingtable-wrapper-box [class*='col-']:last-child .style4 {
  border-radius: 0 30px 30px 0;
}
.border-ltr {
  border: 1px solid #dddddd;
  border-width: 0 1px;
}
.bg-href:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  height: 250px;
  background-color: #f8f8f8;
  width: 100%;
}
.text-green-dark {
  color: #54b439;
}
.text-blue-dark {
  color: #4a64ff;
}
.title-light-bold {
  font-weight: 300;
  font-size: 36px;
  line-height: 40px;
  text-transform: capitalize;
}
.title-light-bold span {
  font-weight: 700;
}

/* Tickit box */
.tickit-box {
  border-radius: 15px;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  padding: 50px 30px;
}
.purple-dark,
.bg-purple-dark {
  background-color: #663398 !important;
}
.text-purple-dark {
  color: #663398 !important;
}
.tickit-box .price span {
  font-size: 24px;
  font-weight: 200;
  position: absolute;
  top: -30px;
  left: 0;
}
.tickit-box .title {
  text-transform: uppercase;
  letter-spacing: 3px;
  color: #000;
  font-size: 13px;
}
.tickit-box p {
  line-height: 30px;
  font-size: 18px;
}
.tickit-box .price {
  font-size: 130px;
  line-height: 130px;
  font-weight: bold;
  position: relative;
  padding-left: 20px;
  width: -moz-max-content;
  margin: 0 auto;
}
.tickit-info h2.font-50 {
  font-weight: 700;
}
.tickit-info p {
  font-size: 27px;
  line-height: 40px;
  color: #f0f0f0;
}
.thumb-img {
  width: 80px;
  border-radius: 50%;
  overflow: hidden;
}
/* Our Stories Blog  */
.our-stories .dez-post-title .post-title {
  margin-top: 5px;
  font-weight: 500;
  font-size: 22px;
  line-height: 30px;
  color: #000;
  text-transform: capitalize;
}
.our-stories .dez-post-media {
  margin-bottom: 15px;
  overflow: hidden;
}
.our-stories .dez-post-text p {
  font-size: 14px;
  font-weight: 400;
  line-height: 25px;
  color: #999999;
}
.our-stories .dez-post-tags span {
  font-size: 12px;
  letter-spacing: 3px;
  text-transform: uppercase;
  color: #b1b1b1;
  font-weight: 700;
}
.tickit-info span {
  letter-spacing: 3px;
  font-weight: 400;
  font-size: 12px;
  text-transform: uppercase;
  color: #f2f2f2;
}
.socail-icon .site-button {
  height: 45px;
  width: 45px;
  padding: 10px 15px !important;
}
.contact-2 .support-box-form label {
  color: #000;
}
.contact-2 .support-box-form .input-group:after {
  background: #808080;
}
.contact-2 .support-form {
  padding: 40px;
  border-radius: 15px;
}
.contact-2 .support-box-form .form-control {
  color: #000;
}
.contact-2 .support-box-form textarea.form-control {
  height: 140px;
}
.contact-info-bx span {
  display: block;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 4px;
}
.contact-info-bx h3 {
  font-size: 28px;
  line-height: 45px;
  font-weight: 300;
  margin-bottom: 30px;
}
@media only screen and (max-width: 991px) {
  .our-service .icon-content.p-lr50 {
    padding: 0;
  }
  .our-stories .dez-post-media img {
    width: 100%;
  }
}
@media only screen and (max-width: 767px) {
  .about-1 .icon-bx-wraper {
    margin-top: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
  .about-1 .about-scale {
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
  .about-1 {
    box-shadow: none;
  }
  .experts-box {
    padding: 0;
    margin-bottom: 30px;
  }
  .contact-info-bx {
    padding-left: 0;
    padding-right: 0;
  }
  .section-head .title-head {
    font-size: 30px;
  }
  .tickit-info h2.font-50 {
    font-size: 30px;
    line-height: 40px;
  }
}
.icon-hover-bx .icon-bx-xl {
  position: relative;
  z-index: 1;
}
.icon-hover-bx:hover .icon-bx-xl {
  border-color: #fff;
}
.icon-hover-bx .icon-bx-xl:after {
  background-color: var(--bg-color);
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  left: 50%;
  top: 50%;
  border-radius: 210px;
  z-index: -1;
  transform: translate(-50%, -50%);
  transition: all 0.5s;
}
.icon-hover-bx .icon-cell {
  width: 100%;
}
.icon-hover-bx:hover .icon-bx-xl:after {
  background-color: var(--bg-color);
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  left: 50%;
  top: 50%;
  border-radius: 210px;
  z-index: -1;
  box-shadow: inset 0 0px 0px 6px rgba(255, 255, 255, 0.3),
    inset 0 0px 0px 15px rgba(255, 255, 255, 0.3);
}
.icon-hover-bx:hover .icon-bx-xl .icon-cell {
  color: #fff;
}

.blog-bx .blog-number,
.team-curve-bx .title a,
.business-footer .footer-top h5,
.testimonial-flip .front .testimonial-detail strong,
.product-box .rating-star li.active,
.product-price h4 {
  color: var(--bg-color);
}
.business-contact .contact-form-bx .input-group:after,
.newsletter-bx .contact-form-bx .input-group:after,
.flip-bx .back {
  background-color: var(--bg-color);
}
.woo-entry .overlay-icon a i {
  padding: 10px;
}
/* Sat Manu */
.header-nav .nav > li .sub-menu.left {
  left: auto;
  right: 0;
}
@media only screen and (max-width: 991px) {
  .sort-title-bx,
  .sort-title-bx::after {
    padding-left: 30px;
  }
  .industry-service {
    padding-bottom: 50px;
  }
}
@media only screen and (max-width: 767px) {
  .table-responsive table {
    min-width: 700px;
  }
  .sort-title-bx::after {
    font-size: 35px;
  }
  .shop-item-rating span {
    text-align: left;
  }
  .shop-item-rating {
    position: relative;
  }
  .industry-service {
    padding-bottom: 30px;
  }
  .our-about-info .dzseth.m-b30 {
    margin-bottom: 0;
  }
  .our-about-info .column-reverse {
    flex-direction: column-reverse;
  }
}
.comments-area .comment-form p input[type='submit'] {
  cursor: pointer;
}
@media only screen and (max-width: 420px) {
  .owl-slider-banner .slide-content h2 {
    font-size: 28px;
    line-height: 40px;
  }
  .owl-slider-banner .slide-content h2 br,
  .section-head .box-title br {
    display: none;
  }
  .section-head .box-title {
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 8px;
  }
  .chosesus-content h3 {
    font-size: 20px;
    line-height: 32px;
    margin-bottom: 30px;
  }
  .inquiry-form {
    padding: 30px;
  }
  .inquiry-form .g-recaptcha {
    margin-bottom: -20px;
  }
  .site-footer .widget h5.m-b30 {
    margin-bottom: 15px;
  }
  .sort-title-bx::after {
    content: none;
  }
  .sort-title-bx {
    padding-left: 15px;
    position: relative;
    padding-bottom: 30px;
  }
}
@media only screen and (max-width: 360px) {
  .abuot-box {
    padding: 0;
  }
  .business-footer .footer-bottom {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
}

/* Footer map */
.site-footer.map-footer iframe {
  height: 100%;
  width: 100%;
  border: 0;
}

/* Footer gray */
.footer-top.bg-gray-dark .site-button.text-white {
  color: #fff;
}
/* button */
button:focus {
  outline: none;
}

.main-slider .slick-dots {
  margin-top: 0;
  position: absolute;
  right: 50px;
  text-align: center;
  top: 62%;
  transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  width: 25px;
  color: rgba(255, 255, 255, 0.5);
}
@media only screen and (max-width: 767px) {
  .main-slider .slick-dots {
    bottom: 146px;
    margin-top: 0;
    position: absolute;
    right: auto;
    text-align: center;
    top: auto;
    transform: translateY(-50%);
    width: 100%;
  }
}
.main-slider .slick-dots li button::before {
  content: none !important;
}
.main-slider .slick-dots li {
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-radius: 20px;
  display: inline-block;
  margin: 4px;
  padding: 4px;
}
.main-slider .slick-dots li.slick-active {
  border-color: #6cc000;
}
.main-slider .slick-dots li.slick-active button {
  background-color: #6cc000;
}
.main-slider .slick-dots li button {
  height: 9px;
  width: 9px;
  margin: 0;
  background: #d6d6d6;
  display: block;
  border-radius: 30px;
  -webkit-backface-visibility: visible;
  -webkit-transition: opacity 200ms ease;
  -moz-transition: opacity 200ms ease;
  -ms-transition: opacity 200ms ease;
  -o-transition: opacity 200ms ease;
  transition: opacity 200ms ease;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
}

.slick-slide.slick-active.slick-current + .slick-active .testimonial-text {
  background-color: #6cc000;
}
.slick-slide.slick-active.slick-current + .slick-active .testimonial-text p {
  color: #fff;
}

.center-active-owl {
}
.center-active-owl .slick-dots li button:before {
  content: none;
}
.center-active-owl .slick-dots li {
  margin: 0;
  width: auto;
}
.center-active-owl .slick-dots li button {
  width: 10px;
  height: 10px;
  margin: 5px 7px;
  opacity: 0.5;
  background: #000;
  display: block;
  -webkit-backface-visibility: visible;
  -webkit-transition: opacity 0.2s ease;
  -moz-transition: opacity 0.2s ease;
  -ms-transition: opacity 0.2s ease;
  -o-transition: opacity 0.2s ease;
  transition: opacity 0.2s ease;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
}
.center-active-owl .slick-dots li.slick-active button {
  opacity: 1;
  width: 25px;
}

@media only screen and (max-width: 1350px) {
  .client-logo-carousel.owl-btn-center-lr .owl-nav {
    display: none;
  }
}
@media only screen and (max-width: 1350px) {
  .dots-style-center.owl-btn-center-lr .owl-nav {
    display: none;
  }
}

.custom-select {
  border-color: #e1e6eb;
  box-shadow: none;
  height: 40px;
  border-width: 2px;
  font-size: 13px;
  line-height: 20px;
}
.custom-select:focus {
  border-color: #636363;
  outline: 0;
  border-width: 2px;
  box-shadow: none;
}

.dots-style-center .slick-dots li button:before {
  content: none;
}
.dots-style-center .slick-dots li {
  margin: 0;
  width: auto;
}
.dots-style-center .slick-dots li button {
  width: 10px;
  height: 10px;
  margin: 5px 7px;
  opacity: 0.5;
  background: #fff;
  display: block;
  -webkit-backface-visibility: visible;
  -webkit-transition: opacity 0.2s ease;
  -moz-transition: opacity 0.2s ease;
  -ms-transition: opacity 0.2s ease;
  -o-transition: opacity 0.2s ease;
  transition: opacity 0.2s ease;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
}
.dots-style-center .slick-dots li.slick-active button {
  opacity: 1;
  width: 25px;
}

.owl-slider-banner .slick-slide.slick-active .slide-content-box {
  top: 50%;
}

.coming-soon {
  height: 100vh;
  position: relative;
}
.countdown-box {
  display: flex;
  line-height: 70px;
  align-items: flex-start;
  padding: 50px 50px 0;
}
.countdown-box .button-home .btn-primary {
  background-color: #6cc000;
  border-color: #6cc000;
}

.coming-head {
  display: block;
  max-width: 1170px;
  text-align: center;
  margin-top: 50px;
}
.coming-head h2 {
  line-height: 162px;
  font-size: 162px;
  display: inline;
  position: relative;
  font-family: 'Roboto', sans-serif;
}
.coming-head h2 span {
  font-size: 35px;
  color: #1b1b1b;
  position: absolute;
  right: 26px;
  line-height: 40px;
}
.coming-head h2 strong {
  color: #1b1b1b;
  font-weight: 900;
}
.coming-head .subscribe-form {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
}
.coming-head .input-group {
  background: #fff;
  border: 1px solid #e3e3e3;
  border-radius: 50px;
}
.coming-head .subscribe-form input {
  background-color: transparent;
  border: 2px solid hsla(0, 0%, 100%, 0.5);
  border-radius: 30px !important;
  height: 50px;
  padding: 10px 20px;
  margin-right: 5px;
  color: #000;
  font-size: 15px;
}
.coming-head .input-group .btn {
  border-radius: 40px;
  height: 40px;
  padding: 0;
  width: 40px;
  margin: 5px;
}
.coming-head .input-group .btn-primary {
  background-color: #6cc000;
  border-color: #6cc000;
}

.countdown {
  margin-left: auto;
}

.countdown .date {
  display: inline-block;
  text-align: center;
  width: 130px;
}
.countdown .date div {
  display: block;
  line-height: 30px;
}
.countdown .date .time {
  font-size: 70px;
  color: #000;
  font-weight: 700;
  display: block;
}
.countdown .date div strong {
  font-size: 20px;
  font-weight: 400;
  color: #8c7d7d;
  text-transform: uppercase;
  box-shadow: inset 0 -4px 0 #6cc000;
  line-height: 18px;
  display: inline-block;
}

.price-slide-2 .MuiSlider-rail,
.price-slide-2 .MuiSlider-track {
  height: 0.4rem;
}
.price-slide-2 .MuiSlider-track {
  background: #6cc000;
}
.price-slide-2 .MuiSlider-rail {
  background: #eee;
  opacity: 1;
}
.price-slide-2 .MuiSlider-thumb {
  height: 22px;
  width: 22px;
  box-shadow: -1px 1px 4px 0 rgba(0, 0, 0, 0.3);
  top: 10px;
  background: #fff;
  border: 2px solid #6cc000;
}
.price-slide-2 .MuiSlider-valueLabel {
  left: calc(-50% - 0px);
  color: #6cc000;
}

@media only screen and (max-width: 991px) {
  .countdown-box {
    display: block;
    padding: 50px 20px 0;
  }
  .countdown-box .button-home {
    position: absolute;
    bottom: 20px;
    left: 20px;
  }
  .coming-head {
    margin-right: unset;
    text-align: left;
    padding-left: 20px;
    padding-right: 20px;
  }
  .countdown {
    margin-left: 0;
  }
  .countdown .date {
    text-align: left;
  }
  .coming-head .subscribe-form {
    margin-left: unset;
  }
  .price-slide-2 .MuiSlider-thumb {
    top: 15px;
  }
}
@media only screen and (max-width: 575px) {
  .coming-head {
    margin-top: 20px;
  }
  .coming-head h2 {
    line-height: 1;
    font-size: 100px;
  }
  .coming-head h2 span {
    font-size: 20px;
    right: 20px;
    line-height: 30px;
  }
  .countdown .date {
    width: 70px;
  }
  .countdown .date .time {
    font-size: 40px;
    line-height: 1;
  }
  .countdown .date div strong {
    font-size: 12px;
    line-height: 12px;
  }
}

.dzseth .img-fix .img-cover {
  height: 92.7%;
}
@media only screen and (max-width: 1800px) {
  .dzseth .img-fix .img-cover {
    height: 99%;
  }
}
@media only screen and (max-width: 1750px) {
  .dzseth .img-fix .img-cover {
    height: 100%;
  }
}
.course-banner {
  height: 550px;
  text-align: center;
}
/* Work Slider */
.main-slider.dz-style-1 .slide-content-box > div {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
.main-slider.dz-style-1 .slide-content-box .dz-title {
  font-size: 70px;
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: 20px;
}
.main-slider.dz-style-1 .slide-item .slide-content {
  padding-bottom: 100px;
}
.main-slider.dz-style-1 .slide-content-box .slide-btns {
  display: flex;
  align-items: center;
}
.main-slider.dz-style-1 .slide-content-box .slide-btns .site-button {
  flex: 0 0 50%;
  max-width: 50%;
  font-size: 16px;
  padding: 20px 20px;
}

.main-slider.dz-style-1 .slide-content-box p {
  font-size: 20px;
  line-height: 1.3;
  color: rgba(255, 255, 255, 0.75);
  font-weight: 400;
  margin-bottom: 30px;
}

.main-slider .owl-nav .owl-next,
.main-slider .owl-nav .owl-prev {
  height: 50px;
  width: 50px;
  line-height: 50px;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  font-size: 40px;
  background: transparent;
}
.main-slider .owl-nav .owl-next {
  right: 20px;
}
.main-slider .owl-nav .owl-prev {
  left: 20px;
}
.main-slider .owl-nav {
  margin-top: 0;
}

/* Work Slider */
.main-slider.dz-style-3 .slide-content-box > div {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
.main-slider.dz-style-3 .slide-content-box .dz-title {
  font-size: 60px;
  font-weight: 500;
  line-height: 1.3;
  margin-bottom: 20px;
  text-transform: uppercase;
  letter-spacing: 2px;
}
.main-slider.dz-style-3 .slide-item .slide-content {
  padding-bottom: 100px;
}
.main-slider.dz-style-3 .slide-content-box .sub-title {
  text-transform: uppercase;
  font-weight: 700;
}
.main-slider.dz-style-3 .slide-content-box .slide-btns .site-button {
  margin: 0 5px;
}
.main-slider.dz-style-3 .slide-content-box p {
  font-size: 20px;
  line-height: 1.3;
  color: rgba(255, 255, 255, 0.75);
  font-weight: 400;
  margin-bottom: 30px;
}
.main-slider.dz-style-3 [class*='-dark']:after,
.main-slider.dz-style-3 [class*='-middle']:after,
.main-slider.dz-style-3 [class*='-light']:after {
  background-image: url(./../images/pt1.png);
}

/* Home 4 Banner */
.home-banner {
  height: 100vh;
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.home-banner:before {
  content: '';
  background-image: url(./../images/curv.png);
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  z-index: 1;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: 100%;
}
.home-banner:after {
  content: '';
  background: -webkit-linear-gradient(
    45deg,
    rgba(24, 75, 215, 0.9) 0%,
    rgba(108, 0, 174, 0.9) 100%
  );
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: -1;
}
.home-banner .home-bnr-inner .sub-title {
  font-size: 45px;
  color: #fff;
  line-height: 1.1;
  animation: dzMove1 10s linear infinite;
}
.home-banner .home-bnr-inner {
  width: 100%;
  height: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.home-banner .home-bnr-inner:before,
.home-banner .home-bnr-inner:after {
  content: '';
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  background-repeat: no-repeat;
  background-position: bottom center;
}
.home-banner .home-bnr-inner:before {
  background-image: url(./../images/main-slider/rev-slider/box-bg2.png);
  background-size: 400px;
  animation: dzMove1 9s linear infinite;
}
.home-banner .home-bnr-inner:after {
  background-image: url(./../images/main-slider/rev-slider/box-bg1.png);
  background-size: 520px;
  animation: dzMove1 11s linear infinite;
}
.home-banner .home-bnr-inner .site-button {
  margin: 0 5px;
  animation: dzMove1 11.5s linear infinite;
}
.home-banner .home-bnr-inner .dz-title {
  font-size: 144px;
  color: #fff;
  line-height: 1.1;
  animation: dzMove1 10.5s linear infinite;
}

@media screen and (max-width: 991px) {
  .home-banner .home-bnr-inner .dz-title {
    font-size: 110px;
  }
}
@media screen and (max-width: 768px) {
  .home-banner .home-bnr-inner .dz-title {
    font-size: 90px;
  }
}
@media screen and (max-width: 575px) {
  .home-banner .home-bnr-inner .dz-title {
    font-size: 60px;
  }
  .home-banner .home-bnr-inner .sub-title {
    font-size: 30px;
  }
  .home-banner .home-bnr-inner .site-button {
    padding: 11px 45px 11px 12px;
    font-size: 15px;
  }
  .home-banner .home-bnr-inner .site-button.btn-icon i {
    height: 38px;
    width: 38px;
    line-height: 38px;
  }
  .home-banner .home-bnr-inner:after {
    background-size: 320px;
  }
  .home-banner .home-bnr-inner:before {
    background-size: 230px;
  }
  .home-banner .home-bnr-inner:before,
  .home-banner .home-bnr-inner:after {
    background-position: center;
  }
}

/* Home 6 Banner */
.home-banner-2 {
  height: 100vh;
  background-size: cover;
  display: flex;
  align-items: center;
  overflow: hidden;
}
.home-banner-2 .curve-img {
  width: 52vw;
  animation: dzMove1 9s linear infinite;
}
.home-banner-2 .sub-title {
  font-family: Poppins, sans-serif;
  color: rgb(132, 132, 132);
  font-size: 20px;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 10px;
  animation: dzMove1 8s linear infinite;
}
.home-banner-2 p {
  animation: dzMove1 8.5s linear infinite;
}
.home-banner-2 .site-button {
  animation: dzMove1 11s linear infinite;
}
.home-banner-2 .dz-title {
  font-size: 60px;
  font-weight: 500;
  line-height: 1.2;
  font-family: Poppins, sans-serif;
  margin-bottom: 15px;
  animation: dzMove1 10s linear infinite;
}
.home-banner-2 .dz-title span {
  font-size: 80%;
}
.home-banner-2 .dz-title strong {
  font-weight: 700;
}
.dzmove1 {
  animation: dzMove1 1s linear infinite;
}

@keyframes dzMove1 {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
  25% {
    -webkit-transform: translate(10px, 10px);
    transform: translate(10px, 10px);
  }
  50% {
    -webkit-transform: translate(5px, 5px);
    transform: translate(5px, 5px);
  }
  75% {
    -webkit-transform: translate(10px, -5px);
    transform: translate(10px, -5px);
  }
  to {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}
@keyframes dzHeart {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(0.9);
  }
}

@media screen and (max-width: 991px) {
  .main-slider.dz-style-1 .slide-content-box .dz-title {
    font-size: 50px;
  }
  .home-banner-2 .sub-title {
    font-size: 16px;
  }
  .home-banner-2 .dz-title {
    font-size: 33px;
  }
}
@media screen and (max-width: 767px) {
  .main-slider.dz-style-3 .slide-content-box .dz-title {
    font-size: 40px;
  }
  .home-banner-2 .curve-img {
    width: 100%;
    margin-top: 30px;
  }
  .home-banner-2 {
    height: auto;
    padding-top: 100px;
  }
}
@media screen and (max-width: 575px) {
  .main-slider.dz-style-1 .slide-content-box .dz-title {
    font-size: 30px;
  }
  .main-slider.dz-style-1 .slide-content-box p {
    font-size: 16px;
  }
  .main-slider.dz-style-1 .slide-content-box .slide-btns {
    display: unset;
  }
  .main-slider.dz-style-1 .slide-content-box .slide-btns .site-button {
    padding: 10px 18px;
  }
  .main-slider.dz-style-1 .slide-content-box > div {
    padding: 0 20px;
  }
  .main-slider .owl-nav .owl-next,
  .main-slider .owl-nav .owl-prev {
    font-size: 30px;
    top: 44%;
  }
  .main-slider .owl-nav .owl-next {
    right: 4px;
  }
  .main-slider .owl-nav .owl-prev {
    left: 4px;
  }
  .main-slider.dz-style-3 .slide-content-box .dz-title {
    font-size: 28px;
  }
}
