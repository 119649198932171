/*
  	Flaticon icon font: Flaticon
  	Creation date: 26/10/2018 10:58
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("./Flaticon.eot");
  src: url("./Flaticon.eot?#iefix") format("embedded-opentype"),
    url("./Flaticon.woff") format("woff"),
    url("./Flaticon.ttf") format("truetype"),
    url("./Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-family: "Flaticon";
    src: url("./Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before,
[class*=" flaticon-"]:before,
[class^="flaticon-"]:after,
[class*=" flaticon-"]:after {
  font-family: Flaticon;
  font-style: normal;
}

.flaticon-checked:before {
  content: "\f100";
}
.flaticon-map-location:before {
  content: "\f101";
}
.flaticon-users:before {
  content: "\f102";
}
.flaticon-android:before {
  content: "\f103";
}
.flaticon-apple:before {
  content: "\f104";
}
.flaticon-rocket-ship:before {
  content: "\f105";
}
.flaticon-city-hall:before {
  content: "\f106";
}
.flaticon-picasa:before {
  content: "\f107";
}
.flaticon-google-play:before {
  content: "\f108";
}
.flaticon-engineer:before {
  content: "\f109";
}
.flaticon-layers:before {
  content: "\f10a";
}
.flaticon-inbox:before {
  content: "\f10b";
}
.flaticon-select-an-objecto-tool:before {
  content: "\f10c";
}
.flaticon-team:before {
  content: "\f10d";
}
.flaticon-trophy:before {
  content: "\f10e";
}
.flaticon-menu:before {
  content: "\f10f";
}
.flaticon-bar-chart:before {
  content: "\f110";
}
.flaticon-city-hall-1:before {
  content: "\f111";
}
.flaticon-tablet:before {
  content: "\f112";
}
.flaticon-pen:before {
  content: "\f113";
}
.flaticon-house:before {
  content: "\f114";
}
.flaticon-money:before {
  content: "\f115";
}
.flaticon-sketch:before {
  content: "\f116";
}
.flaticon-building:before {
  content: "\f117";
}
.flaticon-smartphone:before {
  content: "\f118";
}
.flaticon-placeholder:before {
  content: "\f119";
}
.flaticon-shop:before {
  content: "\f11a";
}
.flaticon-thumb-up:before {
  content: "\f11b";
}
.flaticon-tracking-map:before {
  content: "\f11c";
}
.flaticon-play-button:before {
  content: "\f11d";
}
.flaticon-technology:before {
  content: "\f11e";
}
.flaticon-devices:before {
  content: "\f11f";
}
.flaticon-email:before {
  content: "\f120";
}
.flaticon-left-arrow:before {
  content: "\f121";
}
.flaticon-right-arrow:before {
  content: "\f122";
}
.flaticon-avatar:before {
  content: "\f123";
}
.flaticon-cancel:before {
  content: "\f124";
}
.flaticon-chat:before {
  content: "\f125";
}
.flaticon-speaker:before {
  content: "\f126";
}
.flaticon-like:before {
  content: "\f127";
}
.flaticon-star:before {
  content: "\f128";
}
.flaticon-internet:before {
  content: "\f129";
}
.flaticon-diamond:before {
  content: "\f12a";
}
.flaticon-search:before {
  content: "\f12b";
}
.flaticon-file:before {
  content: "\f12c";
}
.flaticon-calendar:before {
  content: "\f12d";
}
.flaticon-notebook:before {
  content: "\f12e";
}
.flaticon-phone-call:before {
  content: "\f12f";
}
.flaticon-process:before {
  content: "\f130";
}
.flaticon-knowledge:before {
  content: "\f131";
}
.flaticon-mind:before {
  content: "\f132";
}
.flaticon-network:before {
  content: "\f133";
}
.flaticon-leadership:before {
  content: "\f134";
}
.flaticon-hierarchy:before {
  content: "\f135";
}
.flaticon-computer:before {
  content: "\f136";
}
.flaticon-accounting:before {
  content: "\f137";
}
.flaticon-support:before {
  content: "\f138";
}
.flaticon-box:before {
  content: "\f139";
}
.flaticon-calculator:before {
  content: "\f13a";
}
.flaticon-operation:before {
  content: "\f13b";
}
.flaticon-dollar:before {
  content: "\f13c";
}
.flaticon-start:before {
  content: "\f13d";
}
.flaticon-value:before {
  content: "\f13e";
}
.flaticon-handshake:before {
  content: "\f13f";
}
.flaticon-workers:before {
  content: "\f140";
}
.flaticon-organization:before {
  content: "\f141";
}
.flaticon-analysis:before {
  content: "\f142";
}
.flaticon-communications:before {
  content: "\f143";
}
.flaticon-creative:before {
  content: "\f144";
}
.flaticon-marketing:before {
  content: "\f145";
}
.flaticon-management:before {
  content: "\f146";
}
.flaticon-development:before {
  content: "\f147";
}
.flaticon-timer:before {
  content: "\f148";
}
.flaticon-setting:before {
  content: "\f149";
}
.flaticon-strategy:before {
  content: "\f14a";
}
.flaticon-selective:before {
  content: "\f14b";
}
.flaticon-analytics:before {
  content: "\f14c";
}
.flaticon-network-1:before {
  content: "\f14d";
}
.flaticon-maze:before {
  content: "\f14e";
}
.flaticon-business:before {
  content: "\f14f";
}
